import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { AnalyticsDashboardLearnMoreComponent } from '../analytics-dashboard-learn-more/analytics-dashboard-learn-more.component';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit {

  @ViewChild('showDayWiseError', { static: false }) showDayWiseError!: ElementRef;
  @ViewChild('showNintiyDayErrorMsg', { static: false }) showNintiyDayError!: ElementRef;
  groupByFilter = ["day", "week", "month"]

  dashboardSummaryloader: boolean = false;
  grammarSummaryloader: boolean = false;
  paraphraseSummaryloader: boolean = false;
  afeSummaryloader: boolean = false;
  reportSummaryloader: boolean = false;

  grammerDataSource: any = [];
  grammerUsageAppsData: any = [];
  grammerLanguageWiseData: any = [];

  paraphraseDataSource: any = [];
  paraphraseUsageAppsData: any = [];
  paraphraseLanguageWiseData: any = [];

  afeDataSource: any = [];
  afeUsageAppsData: any = [];
  afeLanguageWiseData: any = [];

  journalFinderData: any = [];
  citationCheckerData: any = [];
  plagCheckData: any = [];

  selectedReportName: string = "grammar";
  showDayWiseErrorMsg: boolean = false;
  showNintiyDayErrorMsg: boolean = false;

  isValidFilter: boolean = true;
  userId: string;
  dashboardSummaryData: any = [];
  today = new Date();
  yesterday_date = new Date(new Date().setDate(new Date().getDate() - 1));
  private _dayBeforeWeekDate = new Date(new Date().setDate(new Date().getDate() - 6));
  // minDate = new Date(new Date().setDate(new Date().getDate() - 92));

  filtersSelection: FormGroup = new FormGroup({
    groupBy: new FormControl(this.groupByFilter[0]),
    datepicker: new FormControl([
      this._dayBeforeWeekDate,
      this.today,
    ]),
    start_date: new FormControl(this._dayBeforeWeekDate.toISOString()),
    end_date: new FormControl(this.today.toISOString())
  });
  constructor(private networkCalls: DashboardService,
    public sharedService: SharedService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    this.applyFilter();
  }

  private _getFinalFilters() {
    let parts = this.filtersSelection.get('datepicker').value;
    parts[0].setHours(0, 0, 0);
    parts[1].setHours(23, 59, 59);
    this.filtersSelection.get('start_date').setValue(new Date(parts[0].getTime() - (parts[0].getTimezoneOffset() * 60000)).toISOString()); 
    this.filtersSelection.get('end_date').setValue(parts[1].toISOString());
    let filters = JSON.parse(JSON.stringify(this.filtersSelection.getRawValue()));
    delete filters.datepicker;
    return filters;
  }

  private _fetchDashboardSummary() {
    this.dashboardSummaryloader = true;
    let filters = this._getFinalFilters();

    this.networkCalls.getUsageStatsRequest(filters).subscribe({
      next: response => {
        this.dashboardSummaryloader = false;
        if (response) {
          this.dashboardSummaryData = response.data;
        }
      },
      error: error => {
        this.dashboardSummaryloader = false;
      }
    });
  }

  resetChartData() {
    this.grammerDataSource = [];
    this.grammerUsageAppsData = [];
    this.grammerLanguageWiseData = [];
    this.paraphraseDataSource = [];
    this.paraphraseUsageAppsData = [];
    this.paraphraseLanguageWiseData = [];
    this.afeDataSource = [];
    this.afeUsageAppsData = [];
    this.afeLanguageWiseData = [];
    this.journalFinderData = [];
    this.citationCheckerData = [];
    this.plagCheckData = [];
    this.showDayWiseErrorMsg = false;
  }

  checkFilterValidation(event) {
    let parts = this.filtersSelection.get('datepicker').value;
    parts[0].setHours(0, 0, 0);
    parts[1].setHours(23, 59, 59);

    let days = Math.floor((parts[1].getTime() - parts[0].getTime()) / 1000 / 60 / 60 / 24);
    if (this.filtersSelection.get("groupBy").value == 'day') {
      if (days > 6) {
        this.showDayWiseErrorMsg = true;
        event.stopPropagation();
        return false;
      }
      this.showDayWiseErrorMsg = false;
    }
    if (days > 92) {
      this.showNintiyDayErrorMsg = true;
      event.stopPropagation();
      return false;
    }
    this.showNintiyDayErrorMsg = false;
    return true;
  }
  applyFilter(event?: MouseEvent) {
    this.isValidFilter = this.checkFilterValidation(event);
    if (!this.isValidFilter) {
      return;
    }
    this.resetChartData();
    this._fetchDashboardSummary();

    if (this.selectedReportName == "grammar") {
      this.fetchGrammerWordCountSummary();
    }
    else if (this.selectedReportName == "paraphraser") {
      this.fetchParaphraseWordCountSummary();
    }
    else if (this.selectedReportName == "AFE") {
      this.fetchProofreadWordCountSummary();
    }
    else {
      this.fetchGeneratedReportCountSummary();
    }
  }

  fetchGrammerWordCountSummary() {
    if (this.isValidFilter) {
      this.selectedReportName = "grammar";
      if (this.grammerDataSource.length == 0 && this.grammerUsageAppsData.length == 0 && this.grammerLanguageWiseData.length == 0) {
        this.grammarSummaryloader = true;
        let filters = this._getFinalFilters();

        this.networkCalls.getGrammarWordCountSummary(filters).subscribe({
          next: resp => {
            if (resp) {
              this.grammarSummaryloader = false;
              this.grammerDataSource = resp.data.grammar_chart_data;
              this.grammerUsageAppsData = resp.data.grammar_donut_data;
              this.grammerLanguageWiseData = resp.data.grammar_pie_data;
            }
          },
          error: error => {
            this.grammarSummaryloader = false;
          }
        });
      }
    }
  }

  fetchParaphraseWordCountSummary() {
    if (this.isValidFilter) {
      this.selectedReportName = "paraphraser";
      if (this.paraphraseDataSource.length == 0 && this.paraphraseUsageAppsData.length == 0 && this.paraphraseLanguageWiseData.length == 0) {
        this.paraphraseSummaryloader = true;
        let filters = this._getFinalFilters();

        this.networkCalls.getParaphraseWordCountSummary(filters).subscribe({
          next: resp => {
            if (resp) {
              this.paraphraseSummaryloader = false;
              this.paraphraseDataSource = resp.data.paraphraser_chart_data;
              this.paraphraseUsageAppsData = resp.data.paraphraser_donut_data;
              this.paraphraseLanguageWiseData = resp.data.paraphraser_pie_data;
            }
          },
          error: error => {
            this.paraphraseSummaryloader = false;
          }
        });
      }
    }
  }

  fetchProofreadWordCountSummary() {
    if (this.isValidFilter) {
      this.selectedReportName = "AFE";
      if (this.afeDataSource.length == 0 && this.afeUsageAppsData.length == 0 && this.afeLanguageWiseData.length == 0) {
        this.afeSummaryloader = true;
        let filters = this._getFinalFilters();

        this.networkCalls.getProofreadWordCountSummary(filters).subscribe({
          next: resp => {
            if (resp) {
              this.afeSummaryloader = false;
              this.afeDataSource = resp.data.afe_chart_data;
              this.afeUsageAppsData = resp.data.afe_donut_data;
              this.afeLanguageWiseData = resp.data.afe_pie_data;
            }
          },
          error: error => {
            this.afeSummaryloader = false;
          }
        });
      }
    }
  }

  fetchGeneratedReportCountSummary() {
    if (this.isValidFilter) {
      this.selectedReportName = "reports";
      if (this.journalFinderData.length == 0 && this.citationCheckerData.length == 0 && this.plagCheckData.length == 0) {
        this.reportSummaryloader = true;
        let filters = this._getFinalFilters();

        this.networkCalls.getGeneratedReportCountSummary(filters).subscribe({
          next: resp => {
            if (resp) {
              this.reportSummaryloader = false;
              this.journalFinderData = resp.data.journal_finder_chart_data;
              this.citationCheckerData = resp.data.reference_check_data;
              this.plagCheckData = resp.data.plag_check_data;
            }
          },
          error: error => {
            this.reportSummaryloader = false;
          }
        });
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.showDayWiseErrorMsg) {
      const clickedInside = this.showDayWiseError.nativeElement.contains(event.target);
      if (!clickedInside && this.showDayWiseErrorMsg) {
        this.showDayWiseErrorMsg = false;
      }
    }

    if (this.showNintiyDayErrorMsg) {
      const clickedInside = this.showNintiyDayError.nativeElement.contains(event.target);
      if (!clickedInside && this.showNintiyDayErrorMsg) {
        this.showNintiyDayErrorMsg = false;
      }
    }
  }

  openLearnMore(){
    const dialogRef = this.dialog.open(AnalyticsDashboardLearnMoreComponent, {
      panelClass: 'analytics-dashboard-learn',
      data: {  }
    });
  }
}
