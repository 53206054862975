import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trinka-updates',
  templateUrl: './trinka-updates.component.html',
  styleUrls: ['./trinka-updates.component.scss']
})
export class TrinkaUpdatesComponent implements OnInit {
  // isNewEditor: boolean = false;
  swtichToOldNewEditor: boolean = true;
  constructor(private dialogRef: MatDialogRef<TrinkaUpdatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }
  closeModal(param){
    this.dialogRef.close(param)
  }
  
}
