import { Injectable, Inject } from '@angular/core';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ShareWidgetComponent } from '../components/dashboard/share-widget/share-widget.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguagePreferencesComponent } from '../components/language-preferences/language-preferences.component';
import { FileUploadComponent } from '../components/dashboard/file-upload/file-upload.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignInSignOutService } from './networkcalls/sign-in-sign-out.service';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { LoginOutput } from '../_enums/login-output.enum';
import { throwError, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DashboardService } from './networkcalls/dashboard.service';
import { HttpResponse } from '@angular/common/http';
import { WhiteList } from "src/app/_enums/whitlist.enum";
import { FeaturePermission } from '../_interfaces/feature-permission.interface';
import { PluginsService } from './plugins.service';
import { AuthorOneReport } from '../_interfaces/author-one/author-one-report';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { SignupaccessComponent } from '../_modal/signupaccess/signupaccess.component';
import { GetMetaService } from './get-meta.service';
import { PlagiarismReport } from '../_interfaces/editor/plagiarism-report';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  win = window['dataLayer'] || {}
  signInpopUp;
  isSignIn: boolean = false;
  userId: string;
  currentFileId: string;
  enableSignInPanel: number = 0;
  filterCheckList = [];
  CCFilterCheckList = [];
  renameFile: any;
  offsetSeconds = 5;
  featurePermission = new FeaturePermission();
  autoEditFileCredit: number = 0;
  a1ReportData = new AuthorOneReport();
  plagiarismReportData = new PlagiarismReport();
  myDriveUnmappedFilesCount: number = 0;
  archiveFilesCount: number = 0;
  referenceEditorData: any;
  currentFileName: string;
  plagiarism_type: string;
  inclusiveLanguageFilterOptions = {
    'age_bias': { order: 1, title: 'Age Bias', checked: true, display: true, color: '#0E7490', labelPosition: "after", error_category: 'Age Bias' },
    'disability_bias': { order: 2, title: 'Disability Bias', checked: true, display: true, color: '#1D4ED8', labelPosition: "after", error_category: 'Disability Bias' },
    'gender_bias': { order: 3, title: 'Gender Bias', checked: true, display: true, color: '#166534', labelPosition: "after", error_category: 'Gender Bias' },
    'gender_coded_words': { order: 4, title: 'Gender-Coded Words', checked: true, display: true, color: '#C2401C', labelPosition: "after", error_category: 'Gender-Coded Words' },
    'nationality_bias': { order: 5, title: 'Nationality Bias', checked: true, display: true, color: '#7E22CE', labelPosition: "after", error_category: 'Nationality Bias' },
    'racial_bias': { order: 6, title: 'Racial Bias', checked: true, display: true, color: '#B45309', labelPosition: "after", error_category: 'Racial Bias' },
    'religion_bias': { order: 7, title: 'Religion Bias', checked: true, display: true, color: '#414E62', labelPosition: "after", error_category: 'Religious Bias' },
    'other_bias': { order: 8, title: 'Other Bias', checked: true, display: true, color: '#DC2626', labelPosition: "after", error_category: 'Other Bias' },
  };

  trinkaGrammarObject: any = {};
  trinkaParaphraserObject: any = {};
  grammarModuleConfig: any = {
    language: 'english',
    langCode: 'en',
    style_guide: {
      value: 'AMA',
      title: 'AMA (11th ed)'
    },
    document_type: '3',
    power_mode: '',
    source: 'CLOUD',
    inclusive_lang: true,
    inclusiveErrorCategories: [],
    instructionModule: {
      module: 'advanced',
      instruction: ''
    },
    userType: 'BASIC'
  };
  instructionModule:any = {
    module: 'advanced',
    instruction: '',
    languages: ''
  }

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
    public dialog: MatDialog,
    private route: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public jwtHelper: JwtHelperService,
    public loginservice: SignInSignOutService,
    public pluginService: PluginsService,
    public networkCalls: DashboardService,
    public amplitudeService: AmplitudeService,
    public metaString: GetMetaService
  ) {
    this.filterCheckList = [
      /* 0 */{ name: "Grammar", class: "ilf-grammmar", order: { 'regular': 0, 'legal': 5 },  disabled: false, checked: true, flag: 1, errorCount: 0, display: true, labelPosition: "after" },
      /* 1 */{ name: "Spelling", class: "", order: { 'regular': 1, 'legal': 2 },  disabled: false, checked: true, flag: 2, errorCount: 0, display: true, labelPosition: "after" },
      /* 2 */{ name: "Writing Advisor", class: "ilf-writing-advisor", order: { 'regular': 2, 'legal': 6 },  disabled: false, checked: true, flag: 3, errorCount: 0, display: true, labelPosition: "after" },
      /* 3 */{ name: "Enhancements", class: "", order: { 'regular': 3, 'legal': 3 },  disabled: false, checked: true, flag: 4, errorCount: 0, display: true, labelPosition: "after" },
      /* 4 */{ name: "Style Guide", class: "", order: { 'regular': 4, 'legal': 1 },  disabled: false, checked: true, flag: 5, errorCount: 0, display: true, labelPosition: "after" },
      /* 5 */{ name: "Inclusive Language", class: "ilf-inclusive-language", order: { 'regular': 5, 'legal': 4 },  disabled: false, checked: true, flag: 10, errorCount: 0, display: true, labelPosition: "after" },
      /* 6 */{ name: "Legal Writing Style", class: "ilf-legal-writing", order: { 'regular': 6, 'legal': 0 },  disabled: false, checked: true, flag: 11, errorCount: 0, display: true, labelPosition: "after" },
    ];

    this.CCFilterCheckList = [
      {
        name: "Dashes",
        displayName: "Dashes",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      }, {
        name: "Spelling",
        displayName: "Spelling",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "Numbers",
        displayName: "Numbers",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "Symbol",
        displayName: "Style",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      }, {
        name: "Punctuations",
        displayName: "Punctuation",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      }, {
        name: "Spacing",
        displayName: "Spacing",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "table_title",
        displayName: "Table title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "figure_title",
        displayName: "Figure title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "box_title",
        displayName: "Box title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "boxes_title",
        displayName: "Boxes title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "p_value",
        displayName: "P Value",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "accent_chars",
        displayName: "Accent characters",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      // {
      //   name: "symbol_spacing",
      //   displayName: "Symbol before spacing",
      //   disabled: false,
      //   checked: true,
      //   labelPosition: "after",
      //   flag: 1,
      //   errorCount: 0,
      //   display: true
      // },
      {
        name: "tables_title",
        displayName: "Tables title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "figures_title",
        displayName: "Figures title",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      },
      {
        name: "symbol_spacing",
        displayName: "Symbol before spacing",
        disabled: false,
        checked: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
      }

    ];
  }

  openShareDialog() {
    this.dialog.open(ShareWidgetComponent);
    this.amplitudeService.logAmplitude('refer_view', {
      'userID': this.userId
    });
  }

  openSignUpAccessDialog() {
    this.dialog.open(SignupaccessComponent)
  }

  openLanguagePreferences() {
    this.dialog.open(LanguagePreferencesComponent)
  }

  openFileUploadDialog() {
    this.dialog.open(FileUploadComponent);
  }


  getLocalStorageMeta() {
    try {
      const storg = this.storage.get(this.metaString.getMeta());
      if (storg) { if (storg['token']) { return JSON.parse(atob(storg['token'].split('.')[1])) }; }
      return false;
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if (storg['token']) { return JSON.parse(atob(storg['token'].split('.')[1])) }; }
      return false;
    }

  }


  refreshToken() {
    let payload = {
      "token": this.storage.get(this.metaString.getMeta())['token'],
      "refresh_token": this.storage.get(this.metaString.getMeta())['refresh_token']
    }

    return this.loginservice.requestAccessToken(payload)
      .pipe(tap((result: LoginOutput) => {
        this.storage.set(this.metaString.getMeta(), result);
      }), catchError(error => {
        if (error.status === 401) {
          this.storage.set(this.metaString.getMeta(), {});
          window.location.href = window.location.origin;
          return throwError(error);
        }
      }));
  }



  getAccessToken() {
    try {
      if (!this.isAccessTokenExpired()) {
        const storg = this.storage.get(this.metaString.getMeta());
        if (storg) {
          if (storg['token']) {
            return storg['token']
          };
        }
        return '';
      } else {
        this.refreshToken().subscribe(result => {
        }, error => {
          console.log(error)
        })
        return this.storage.get(this.metaString.getMeta())["token"];
      }
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if (storg['token']) { return storg['token'] }; }
      return '';
    }
  }


  getRefreshToken() {
    try {
      const storg = this.storage.get(this.metaString.getMeta());;
      if (storg) { if (storg['refresh_token']) { return storg['refresh_token'] }; }
      return '';
    } catch (error) {
      const storg = localStorage.getItem(this.metaString.getMeta());
      if (storg) { if (storg['refresh_token']) { return storg['refresh_token'] }; }
      return '';
    }
  }

  isAccessTokenExpired(): boolean {
    if (this.storage.get(this.metaString.getMeta()) != undefined && this.storage.get(this.metaString.getMeta())['token'] != '') {
      return this.jwtHelper.isTokenExpired(this.storage.get(this.metaString.getMeta())['token'], this.offsetSeconds)
      // return this.jwtHelper.isTokenExpired(this.storage.get(this.metaString.getMeta())['token'],5)
    };
    return true;
  }


  SignOut(isDeleted = false, emailId = ""): void {
    var logout_payload = {
      "refresh_token": this.getRefreshToken(),
      "access_token": this.getAccessToken()

    };
    this.pluginService.handleLogout();
    this.spinner.show();
    this.loginservice.logout(logout_payload).subscribe(result => {
      this.amplitudeService.logAmplitude('Logout', {});
      this.spinner.hide();
    }, error => {
      this.errorHandller(error);
    });


    if (isDeleted) {
      this.route.navigate(['/feedback/' + emailId])
    }
    else {
      this.storage.set(this.metaString.getMeta(), {});
      this.route.navigate(['/signin'])
    }



  }



  // downloadFile() {
  //   window.location.href = environment.server_address + "/trinka/api/v1/user/" + this.userId + "/file/" + this.currentFileId + "/export";
  // }

  downloadFileAutoEdit(fileId, file, creditsDetails, bool?) {
    this.spinner.show();
    this.networkCalls.downloadAutoEdit(fileId).subscribe(response => {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'action': 'download',
        'score': file.error_report.language_score,
        'revision': file.error_report.total_corrections + file.error_report.total_suggestions,
        'event_name': 'download_edited_file',
        'event': 'download_edited_file_event'
      });


      this.spinner.hide();
      let url: string = response['data']

      window.location.href = url;

      this.amplitudeService.logAmplitude('autoedit_file_download', {
        'fileID': fileId,
        'file_name': file.filename,
        'File Processing start time': new Date(file.process_start_time),
        'File processing end time': new Date(file.process_end_time),
        "status": "success",
        "word count": file.word_count,
        "credit_balance_free": creditsDetails.credits,
        "credit_balance_purchased": creditsDetails.purchased_credits,
        "fileType": file.parentExtension
      });
    }, error => {
      this.toastr.error("Unable to download file", 'Error')
      this.spinner.hide();
      this.amplitudeService.logAmplitude('autoedit_file_download', {
        'fileID': fileId,
        'file_name': file.filename,
        'File Processing start time': new Date(file.process_start_time),
        'File processing end time': new Date(file.process_end_time),
        "status": "failed",
        "word count": file.word_count,
        "credit_balance_free": creditsDetails.credits,
        "credit_balance_purchased": creditsDetails.purchased_credits,
        "fileType": file.parentExtension
      });
    })
  }

  downloadFile(bool?) {
    this.spinner.show();
    this.networkCalls.download(this.userId, this.currentFileId, bool).subscribe((response: HttpResponse<Blob>) => {

      this.spinner.hide();
      let filename: string = this.getFileName(response)
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // this.win.dataLayer.push({
      //   'action': 'click',
      //   'option_type': '<option_type>',
      //   'event_name': 'download_file',
      //   'event': 'download_event'
      //    });
      this.amplitudeService.logAmplitude('file_download', {
        'fileID': this.getCurrentFileId(),
        'type': bool ? 'Tracked Changes' : 'Clean File',
        'editor': window.location.pathname.includes('main-v1') ? 'V1' : 'V0' 
      });
    }, error => {
      this.toastr.error("Unable to download file", 'Error')
      this.spinner.hide();
    });
  }

  setPlagarismData(fileId, userId, plagarismType) {
    this.currentFileId = fileId;
    this.userId = userId;
    this.plagiarism_type = plagarismType;
    localStorage.setItem('fileId', this.currentFileId)
  }

  downloadPlagiarismFile(user_decoded_id) {
    this.spinner.show();
    this.networkCalls.downloadFile(this.userId, this.currentFileId, this.plagiarism_type, user_decoded_id).subscribe((response: HttpResponse<Blob>) => {

      this.spinner.hide();
      let filename: string = this.getFileName(response)
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }, error => {
      this.toastr.error("Unable to download file", 'Error')
      this.spinner.hide();
    });
  }

  private getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('Content-Disposition');
      const r = /(?:filename=")(.+)(?:")/
      filename = r.exec(contentDisposition)[1];
    } catch (e) {
      filename = 'Untitled.docx'
    }
    return filename
  }

  setCurrentFileAndUserId(userId, file_id) {
    this.userId = userId;
    this.currentFileId = file_id;
    localStorage.setItem('fileId', this.currentFileId)
  }

  getCurrentFileId() {
    var fileId = localStorage.getItem('fileId');
    return fileId;
  }

  setCurrentFileName(fileName) {
    this.currentFileName = fileName;
    localStorage.setItem('fileName', this.currentFileName)
  }

  getCurrentFileName() {
    let fileName = localStorage.getItem('fileName');
    return fileName;
  }

  errorHandller(error) {
    let alert_message = error.error.message ? error.error.message : 'Something went wrong';
    switch (error.status) {
      case 401:
        this.toastr.error(alert_message, 'Error');
        this.SignOut();
        break;
      case 403:
        this.toastr.error(alert_message, 'Error');
        this.storage.set(this.metaString.getMeta(), {});
        window.location.href = window.location.origin;
        break;
      case 400:
        this.toastr.error(alert_message, 'Error');
        break;
      case 404:
      case 409:
      case 500:
      case 502:
        this.toastr.error(alert_message, 'Error');
        break;

      default:
        // this.toastr.error("Please check your network connection and try again.", 'Error');
        break;
    }
    this.spinner.hide();
  }

  errorHandlerForFIleSave(error) {
    console.log(error)
    switch (error.status) {
      case 401:
        this.SignOut();
        break;
      case 403:
        this.storage.set(this.metaString.getMeta(), {});
        window.location.href = window.location.origin;
        break;
      default:
        break;
    }
    this.spinner.hide();
  }

  updateFileName(file) {
    this.renameFile = file;
  }

  mainLogo() {
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "../../../assets/images/Trinka_Logo.svg"
      case WhiteList.CLIENT:
        return "../../../assets/images/blank_main_editor_logo.png"
      case WhiteList.SMARTMANUSCRIPT:
        return "../../../assets/images/blank_main_editor_logo.png"
      default:
        return "../../../assets/images/Trinka_Logo.svg"
    }
  }

  threeDotLogo() {
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "../../../../assets/images/trinka_dot_logo.png"
      case WhiteList.CLIENT:
        return "../../../assets/images/blank_editor_logo.png"
      case WhiteList.SMARTMANUSCRIPT:
        return "../../../assets/images/blank_editor_logo.png"
      default:
        return "../../../../assets/images/trinka_dot_logo.png"
    }

  }

  brandName() {
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return "Trinka"
      case WhiteList.CLIENT:
        return ""
      case WhiteList.SMARTMANUSCRIPT:
        return ""
      default:
        return "Trinka"
    }
  }

  whiteListDisplay() {
    switch (window.location.host) {
      case WhiteList.TRINKA:
        return true
      case WhiteList.CLIENT:
        return true
      case WhiteList.SMARTMANUSCRIPT:
        return false
      default:
        return true
    }
  }

  detectCurrentBrowser() {
    if (navigator.userAgent.indexOf("Edg") != -1) {
      return 'Edge';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    }
    else {
      return 'Unknown'
    }
  }

  generateAddCreditsPayload(userid: any, user_type: any, callback_url: any) {

    return {
      "user_id": userid,
      "is_premium": user_type == 'BASIC' ? false : true,
      "trinka_callback_url": callback_url
    }
  }

  uppercaseFirstLetter(phrase): string {
    if (!phrase) return;
    return phrase
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

}
