export class FeaturePermission {

    "status": boolean;
    "message": string;
    "data": {
        enago_proofreading: boolean,
        add_to_dictionary: boolean,
        accept_all_alerts: boolean,
        style_guide: boolean,
        track_changes: boolean,
        document_style: boolean,
        auto_edit: boolean,
        auto_edit_score_popup: boolean,
        consistency_checker: boolean,
        extension_route: boolean,
        publication: boolean,
        revision_tracks_comments: boolean,
        browser_caching: boolean,
        ref_check:boolean
        power_mode: boolean,
        plagiarism_check: boolean,
        journal_finder: boolean,
        trinka_labs: boolean,
        analytics_dashboard: boolean,
        trinka_ai_apps: boolean
    }
    constructor() {
        var temp = {
            enago_proofreading: false,
            add_to_dictionary: false,
            accept_all_alerts: false,
            style_guide: false,
            track_changes: false,
            document_style: false,
            auto_edit: false,
            auto_edit_score_popup: false,
            consistency_checker: false,
            extension_route: false,
            publication: false,
            revision_tracks_comments: false,
            browser_caching: false,
            power_mode: false,
            ref_check: false,
            plagiarism_check: false,
            journal_finder: false,
            trinka_labs: false,
            analytics_dashboard: false,
            trinka_ai_apps: false
        }
        this.status = false;
        this.message = "";
        this.data = temp
    }
}
