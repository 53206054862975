import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashbaordComponent } from './dashbaord.component';
import { ManageProfileComponent } from 'src/app/components/dashboard/manage-profile/manage-profile.component';
// import { MydriveComponent } from 'src/app/components/dashboard/mydrive/mydrive.component';
import { MainComponent } from 'src/app/components/dashboard/main/main.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { MyDictionaryComponent } from 'src/app/components/dashboard/my-dictionary/my-dictionary.component';
import { AutoEditComponent } from 'src/app/components/dashboard/auto-edit/auto-edit.component';
import { PlagiarismCheckComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-check.component';
import { ReferenceQualityStartComponent } from 'src/app/components/dashboard/reference-quality/reference-quality-start/reference-quality-start.component';
import { JournalFinderToolComponent } from 'src/app/components/journal-finder/journal-finder-tool/journal-finder-tool.component';
import { PlagCheckToolPageComponent } from 'src/app/components/plagiarism-check/plag-check-tool-page/plag-check-tool-page.component';
import { AutoEditUploadedFileComponent } from 'src/app/components/dashboard/auto-edit-uploaded-file/auto-edit-uploaded-file.component';
import { AnalyticsDashboardComponent } from 'src/app/components/dashboard/analytics-dashboard/analytics-dashboard.component';

const routes: Routes = [
  { path: '', component: DashbaordComponent, children: [
  {path:'',redirectTo:'mydrive', pathMatch:'full', canActivate: [AuthGuardService]  },
  { path: 'main', component: MainComponent, canActivate: [AuthGuardService]},

  { path: 'mydrive', loadChildren: () => import('../my-drive-master/my-drive-master.module').then(m => m.MyDriveMasterModule)},
  { path: 'profile', component: ManageProfileComponent, canActivate: [AuthGuardService]},
  { path: 'mydictionary', component: MyDictionaryComponent, canActivate: [AuthGuardService]},
  { path: 'auto-edit', component: AutoEditComponent, canActivate: [AuthGuardService]},
  { path: 'citation-check', component: ReferenceQualityStartComponent, canActivate: [AuthGuardService]},
  { path: 'plagiarism-checker', component: PlagCheckToolPageComponent, canActivate: [AuthGuardService] },
  { path: 'journal-finder-tool', component: JournalFinderToolComponent, canActivate: [AuthGuardService] },
  { path: 'auto-edit-uploaded-file', component: AutoEditUploadedFileComponent, canActivate: [AuthGuardService]},
  { path: 'analytics-dashboard', component: AnalyticsDashboardComponent, canActivate: [AuthGuardService]}
] },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashbaordRoutingModule { }
