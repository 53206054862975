import { Component, Inject, OnInit } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-downloadfilepopup',
  templateUrl: './downloadfilepopup.component.html',
  styleUrls: ['./downloadfilepopup.component.scss']
})
export class DownloadfilepopupComponent implements OnInit {

  check: boolean = false;
  userId: string = "";
  constructor(
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<DownloadfilepopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
    ) {
      this.userId = data.userId;
     }

  ngOnInit() {
  }

  onSelectionChange(e) {
    if (e.value == true) {
      this.check = e.value;
    }
    else {
      this.check = e.value;
    }
  }

  download(flag) {
    this.sharedService.userId = this.userId;
    this.sharedService.downloadFile(flag);
    this.dialogRef.close();
  }

}
