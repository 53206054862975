import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { FreeTrialModelComponent } from './free-trial-model/free-trial-model.component';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';
import { profile_object } from 'src/app/_interfaces/editor/profile';

@Component({
  selector: 'app-power-mode',
  templateUrl: './power-mode.component.html',
  styleUrls: ['./power-mode.component.scss']
})
export class PowerModeComponent implements OnInit {

  powerModeChecked = false;
  trialMode : any;
  powerModePremiumStatus: any;
  timeLeft : any;
  userId: any;
  profileData = profile_object;
  @Input('powerModeMain') powerModeStatus: any;
  @Output() outputAlertsFromPowerMode= new EventEmitter<{status }>();

  constructor( private dialog: MatDialog, 
    private subjectSharedService: SubjectiveBehaviorSharedService,
    private dashboardService: DashboardService,
    private sharedService: SharedService) {
      this.getTrialModeStatus();
      this.getPowerModePremiumStatus();
      const localMeta = this.sharedService.getLocalStorageMeta();
      this.userId = btoa(localMeta.sub);
      this.loadSelectedProfileData();
      this.getBasicUserPowerModeTrialData();
   }

  ngOnInit() { 
    
   }

  loadSelectedProfileData(){
    this.dashboardService.getStoreUserProfileDetails().subscribe(resp => {
      if(Object.keys(resp).length == 0){
        this.dashboardService.getSelectedProfileData(this.userId).subscribe(result => {
          this.profileData = result['data']['profile_data'];
          this.subjectSharedService.setBasicUserPowerModeTrialData(this.profileData);
          this.timeLeft = this.profileData.powermode_available_time * 1000;
          this.dashboardService.storeUserProfileDetails(this.profileData);
        });
      }
      else{
        this.profileData = resp;
      }
    });
  }

  getBasicUserPowerModeTrialData(){
    this.subjectSharedService.getBasicUserPowerModeTrialData.subscribe(result=>
      {
        this.timeLeft = result.powermode_available_time * 1000;
        this.profileData.powermode_trail_status = result.powermode_trail_status;
        this.profileData.powermode_start_time = result.powermode_start_time;
        this.profileData.powermode_end_time = result.powermode_end_time;
        this.profileData.powermode_available_time = result.powermode_available_time;
        this.outputAlertsFromPowerMode.emit({status: this.profileData.powermode_trail_status});
      });
  }

  getSelectedProfileData(){
    this.dashboardService.getSelectedProfileData(this.userId).subscribe(result => {
      this.profileData = result['data']['profile_data'];
      this.subjectSharedService.setBasicUserPowerModeTrialData(this.profileData);
      this.timeLeft = this.profileData.powermode_available_time * 1000;
    });
  }

  getTrialModeStatus(){
    this.subjectSharedService.getTrialMode.subscribe(trailModeStatus =>{
      this.trialMode = trailModeStatus;
  });
  }

  getPowerModePremiumStatus(){
    this.subjectSharedService.getPowerModePremiumStatus.subscribe(powerModeResp =>{
      this.powerModePremiumStatus = powerModeResp;
    });
  }

  startStopTrial(event){
    this.getSelectedProfileData();
    if (!this.profileData.powermode_trail_status && this.profileData.powermode_end_time != null) {
      //basic, Trail already ended
      //user to get choice to buy premium
      this.profileData.powermode_trail_status = !this.profileData.powermode_trail_status;
      this.trialMode = false;
      this.dialog.open(FreeTrialModelComponent,  {
        data: { 
          trial: "trial_end"
        }
      });
      
    } else {
      if (this.profileData.powermode_trail_status && this.profileData.powermode_available_time > 0) {
        //basic, trail in progress
        //user to get choice to end
        //set the checked according to user choice
        //show the ticker
        this.profileData.powermode_trail_status = !this.profileData.powermode_trail_status;
              var request={
              "power_mode": event.checked
            }
            this.dashboardService.putselectedUserProfile(this.userId, request).subscribe(result=>{
              if(result['status']){
                this.profileData= result['data']['profile_data']
                this.subjectSharedService.setBasicUserPowerModeTrialData(this.profileData);
                this.trialMode = false;
              }
              this.getBasicUserPowerModeTrialData();
        });

      } else {
        //basic, Trail not taken yet
        //user to get choice to start
        //set the checked according to user choice
        this.profileData.powermode_trail_status = !this.profileData.powermode_trail_status;
        this.dialog.open(FreeTrialModelComponent,  {
          data: { 
            trial: "trial_start"
          }
        });
        this.getTrialModeStatus();
        this.getBasicUserPowerModeTrialData()
      }
    }
    
  }

  togglePremium(event: MatSlideToggleChange){
    this.powerModePremiumStatus = event.checked;
    this.subjectSharedService.setPowerModePremiumStatus(this.powerModePremiumStatus);
    this.outputAlertsFromPowerMode.emit({status: this.powerModePremiumStatus});
  }

  keepEditing(){  }

  turnOffEditing(){
    this.subjectSharedService.setTrialMode(false);
  }
}
