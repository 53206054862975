import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
//import { calculatePasswordStrength } from 'src/app/_directives/customValidators';


export enum Colors {
  primary = 'primary',
  accent = 'accent',
  yellow = 'yellow',
  warn = 'warn'
}


export enum Criteria {
  at_least_eight_chars,
  at_least_one_lower_case_char,
  at_least_one_upper_case_char,
  at_least_one_digit_char,
  at_least_one_special_char,
}

@Component({
  selector: 'password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements  OnInit, OnChanges {
  @Input()
  password: string;


  @Input()
  validators: Criteria[] = Object.keys(Criteria).map(key => Criteria[key]);


  @Input()
  externalError: boolean;


  @Output()
  onStrengthChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resetPasswordStrenth: EventEmitter<any> = new EventEmitter<any>();

  

  criteriaMap = new Map<Criteria, RegExp>();

  passwordFormControl: AbstractControl;


  private _strength: number;


  private _color: string;


  constructor() { 
    this.passwordFormControl = new FormControl('',
      [...this.validators.map(criteria => Validators.pattern(this.criteriaMap.get(criteria)))]);
}
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalError && changes.externalError.firstChange) {
      this._color = Colors.primary;
      return;
    }
    if (changes.externalError && changes.externalError.currentValue) {
      this._color = Colors.warn;
      return;
    }
    //this.password && this.password.length > 0 ? this.calculatePasswordStrength() : this.reset();
  }


  get strength(): number {
    return this._strength ? this._strength : 0;
  }


  get color(): string {


    if (this._strength <= 40) {
      return Colors.warn;
    }if (this._strength <= 60) {
      return Colors.accent;
    } if (this._strength <= 80) {
      return Colors.accent;
    } else {
      return Colors.primary;
    }
  }

  // calculatePasswordStrength() {
  //   let result = calculatePasswordStrength(this.password)
  //   this._strength = result.value;
  //   this.onStrengthChanged.emit(result);

  // }


  reset() {
    this._strength = 0;
    this.resetPasswordStrenth.emit();
    
  }

}
