<ngx-spinner></ngx-spinner>
<div class="plagCheckPlansTable">
    <table class="table">
        <thead>
            <tr>
                <th></th>
                <th>Score Only
                    <div class="headerPoweredBy">
                        <!-- <span>Powered by</span> -->
                        <!-- <img src="../../../../assets/images/Turnitin_logo.svg" alt="" style="width: 44px;"> -->
                        <!-- <img *ngIf="userType !== 'BASIC'" src="../../../../assets/images/ithentication_img.svg" alt="" style="width: 70px;"> -->
                    </div>
                </th>
                <th>
                    <div class="popularTextBox">
                        <h2>Popular</h2>
                    </div>
                    Standard Check
                    <div class="headerPoweredBy" style="visibility: hidden;">
                        <span>Powered by</span>
                        <img src="../../../../assets/images/Turnitin_logo.svg" alt="" style="width: 44px;">
                    </div>
                </th>
                <th>
                    <div class="RecommendedText">
                        <h2>Highest Quality</h2>
                    </div>
                    Advanced Check
                    <div class="headerPoweredBy">
                        <span>Powered by</span>
                        <img src="../../../../assets/images/ithentication_img.svg" alt="" style="width: 70px;">
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let plan of optionList">
                <td [innerHTML]="plan.name"></td>
                <td *ngIf="plan.score">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.score">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>

                <td *ngIf="plan.standard">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.standard">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>

                <td *ngIf="plan.advance">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7071 6.30071C21.0976 6.69123 21.0976 7.3244 20.7071 7.71492L10.7071 17.7149C10.3166 18.1054 9.68342 18.1054 9.29289 17.7149L4.29289 12.7149C3.90237 12.3244 3.90237 11.6912 4.29289 11.3007C4.68342 10.9102 5.31658 10.9102 5.70711 11.3007L10 15.5936L19.2929 6.30071C19.6834 5.91018 20.3166 5.91018 20.7071 6.30071Z" fill="#1E2938"/>
                    </svg>
                </td>
                <td *ngIf="!plan.advance">
                    <svg width="9" height="3" viewBox="0 0 9 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0.639914V2.29545H0V0.639914H9Z" fill="#99A2B2"/>
                    </svg>
                </td>
            </tr>
            <!-- <tr>
                <td>Powered by</td>
                <td><img src="../../../../assets/images/ithentication_img.svg" alt=""></td>
                <td><img src="../../../../assets/images/Turnitin_logo.svg" alt=""></td>
                <td><img src="../../../../assets/images/ithentication_img.svg" alt=""></td>
            </tr> -->
            <tr>
                <td>Credits required</td>
                <td class="freeCheckedPerMonth">{{total_free_score}} free
                    <span *ngIf="total_free_score == 1">check</span>
                    <span *ngIf="total_free_score == 2">checks</span>
                    per month
                    <br>
                    <span class="basic-word-count-limit" *ngIf="total_free_score == 1">
                        (Upto 500 words)
                    </span>
                </td>
                <!-- [ngClass]="[creditsDetails?.user_type === 'PREMIUM_PLUS' && creditsDetails?.plagiarism_free_page_count_scan > getPageCount(cloudDriveFileDetails.word_count) ? 'underline-text' : '', 'freeCheckedPerMonth']"​ -->
                <td class="freeCheckedPerMonth widthUnderLineFreeCheck" [ngClass]="source != 'sample_card'? '' : 'removeUnliderLineSampleReport' ">{{ isPremiumPlusUser ? getPageOrCreditCount() : getStandardPlagCheckCreditCount(word_count)}}
                    <div class="availablePagePopup" *ngIf="isPremiumPlusUser && getPageOrCreditCount().includes('Page') && source != 'sample_card'">
                        <span>For Standard Plagiarism Check</span>
                        <h3>1 Page will be used per 250 words</h3>
                        <div class="availablePagePopupFooter">

                            <p class="pages-info-popup-credit-details" *ngIf="getPageOrCreditCount().includes('Page')"><i class="light-color">Your file has approximately </i>{{word_count | number}} words so maximum {{getPageCount(word_count)}} page<i *ngIf="getPageCount(word_count) > 1">s</i> will be used.</p>
                            <p class="pages-info-popup-credit-details low-credit" *ngIf="!getPageOrCreditCount().includes('Page')">You need {{getPageCount(word_count) | number}} Pages. Since you are low on Pages, {{getStandardPlagCheckCreditCount(word_count)}} will be used</p>
                        </div>
                    </div>

                    <div class="availablePagePopup" *ngIf="!isPremiumPlusUser && !getPageOrCreditCount().includes('Page') && source != 'sample_card'">
                        <span>For Standard Plagiarism Check</span>
                        <h3>12 Credits are used per 25,000 words</h3>
                        <div class="availablePagePopupFooter">
                            <p class="pages-info-popup-credit-details"><i class="light-color">Your file has </i>{{word_count | number }} words, therefore {{getStandardPlagCheckCreditCount(word_count)}} will be used.</p>
                        </div>
                    </div>
                </td>
                <td class="freeCheckedPerMonth widthUnderLineFreeCheck" [ngClass]="source != 'sample_card'? '' : 'removeUnliderLineSampleReport' ">{{getAdvanceCreditCount(word_count)}}
                    <div class="availablePagePopup" *ngIf="source != 'sample_card'">
                        <span>For Advanced Plagiarism Check</span>
                        <h3>18 Credits are used per 25,000 words</h3>
                        <div class="availablePagePopupFooter">
                            <p class="pages-info-popup-credit-details"><i class="light-color">Your file has </i>{{word_count | number }} words, therefore {{getAdvanceCreditCount(word_count)}} will be used.</p>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <div *ngIf="plansCTAToShow.score == 'IN_PROGRESS'" class="inprogress_file">
                        <div style="left:70px;" class="checkingloader">
                            <div class='loader--text'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                        </div>
                    </div>
                    <div *ngIf="!plagiarism_free_score && !score  && plag_type_number ==1 && plansCTAToShow.score != 'IN_PROGRESS'"
                        class="plagCheckOptionButton">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div *ngIf="plagiarism_free_score && plag_type_number == 1 && !score && plansCTAToShow.score != 'IN_PROGRESS'"
                    style="padding-top: 10px">
                        <span class="">Free check exhausted. Resets on {{free_score_reset_date | date:'d MMM'}}</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="(plag_type_number == 1  && score && plansCTAToShow.score != 'IN_PROGRESS') || (source == 'sample_card')"
                        class="plagCheckOptionButton view_score" (click)="highlightScore()">
                        <span class="">View Score</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="plag_type_number == 3 && score && ithenticateUrl == null && plansCTAToShow.score != 'IN_PROGRESS' && plansCTAToShow.advance != 'IN_PROGRESS'"
                        class="plagCheckOptionButton" (click)="goToCard(file_id,card_id,plag_type_number)">
                        <span class="">View Score</span>
                    </div>
                </td>
                <td>
                    <div *ngIf="plansCTAToShow.standard == 'IN_PROGRESS'" class="inprogress_file">
                        <div style="left:70px;top:15px" class="checkingloader">
                            <div class='loader--text'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                        </div>
                    </div>
                    <div *ngIf="(file.turnitine_final_report == null && plag_type_number != 2) && plansCTAToShow.standard != 'IN_PROGRESS'"
                        class="plagCheckOptionButton" (click)="generatePlagiarismReport(3)">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="plag_type_number != 3 && file.turnitine_final_report != null && plansCTAToShow.standard != 'IN_PROGRESS' && source != 'sample_card'"
                        class="plagCheckOptionButton" (click)="goToCard(file_id,card_id,plag_type_number)">
                        <span class="upload-file-btn button">Show Card</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="(plag_type_number == 3 && plansCTAToShow.standard != 'IN_PROGRESS') || (source == 'sample_card')"
                        class="plagCheckOptionButton" (click)="downloadFile(file,3,source_to_download,'../../../assets/Trinka Plagiarism Sample Report-Standard.pdf','Trinka Plagiarism Sample Report-Standard.pdf',turnitinUrl)">
                        <span class="upload-file-btn button">View Report</span>
                    </div>
                </td>
                <td>
                    <div *ngIf="plansCTAToShow.advance == 'IN_PROGRESS'" class="inprogress_file">
                        <div style="left:70px;" class="checkingloader">
                            <div class='loader--text'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                            <div class='loader--dot'></div>
                        </div>
                    </div>
                    <div *ngIf="(!file.key_advance) && plansCTAToShow.advance != 'IN_PROGRESS'"
                        class="plagCheckOptionButton" (click)="generatePlagiarismReport(2)">
                        <span class="upload-file-btn button">Generate</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="plag_type_number == 2 && plansCTAToShow.advance != 'IN_PROGRESS'"
                        class="plagCheckOptionButton" (click)="downloadFile(file,2,source_to_download,'./../../assets/Trinka Plagiarism Sample Report-Advanced.pdf','Trinka Plagiarism Sample Report-Advanced.pdf',ithenticateUrl)">
                        <span class="upload-file-btn button">View Report</span>
                    </div>
                    <div style="background:#FFFFFF;color: #9333EA;border:1px solid #9333EA ;"
                        *ngIf="plag_type_number == 3 && (file.key_advance) && plansCTAToShow.advance != 'IN_PROGRESS'"
                        class="plagCheckOptionButton" (click)="goToCard(file_id,card_id,plag_type_number)">
                        <span class="upload-file-btn button">Show Card</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>