<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1 class="mat-display-1 mb-0">Proofread file</h1>
        </div>
    </div>
</mat-list>
<app-file-loader [currentUserCredits]="_fileUploadService.currentUserCredits" [currentFileInfo]="_fileUploadService.currentFileInfo" (stopUpload)="stopUploadProgress()"></app-file-loader>
<div appDnd (fileDropped)="onFileChange($event)" class="dnd-overlay">
    <div class="dnd-overlay-borders">
      <div class="text">
        Drop file anywhere
      </div>
    </div>
  </div>
<div class="video_fileUpload_sections">
              <div class="createReportSectin">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-6" style="padding-left: 0;">
                      <div class="reportMainTitle">
                        <h1>Proofread any document with AI</h1>
                        <div class="reportSubTitle">
                            <ul>
                                <li>
                                    <span class="afe-open-book-icon"></span>
                                    <span>Proofread doc/docx/LaTeX files in one go</span>
                                </li>
                                <li>
                                    <span class="pf-language-icon"></span>
                                    <span>
                                        Auto edit files in both English and Spanish
                                        <i>New</i>
                                    </span>
                                </li>
                                <li>
                                    <span class="afe-receive-icon"></span>
                                    <span>Download word file with trackable changes</span>
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6" style="padding-right: 0;">
                      <div class="reportUploadSection">
                        <div class="reportDragOption">
                          <i class="afe-icon-uploadReport"></i>
                          <p>Drag & drop a file to get started</p>
                          <span>doc/docx/LaTeX file type (Max 250 MB)</span>
                          <div class="reportDiviedSection">
                            <span>OR</span>
                          </div>
                          <span class="upload-file-heading">Upload a file from</span>
                          <ul>
                            <li (click)="launchTrinkaMyDrive()">
                              <i class="afe-icon-TrinkaMyDrive"></i>
                              <i class="afe-icon-TrinkaMyDrive-hover"></i>
                              <span>Trinka My Drive</span>
                            </li>
                            <li (click)="autoEditFile.click()">
                              <input (click)="logLocalFileClickedEvent()" type="file" name="auto-edit" id="auto-edit" class="auto-edit-file file-input" [multiple]="false" #autoEditFile (change)="onFileChange($event.target.files)"/>

                              <i class="afe-icon-MyDevice"></i>
                              <i class="afe-icon-MyDevice-hover"></i>
                              <span>My Device</span>
                            </li>
                            <li class="more-options-outer" (click)="showMoreOptionsPanel = true">
                              <div *ngIf="showMoreOptionsPanel" class='reports-dashboard-more-options-outer'>
                                <div class='reports-dashboard-more-options-inner'>
                                  <div (click)="launchDropBoxPicker()" class='reports-dashboard-more-option-single'>
                                    <span class='reports-dashboard-more-option-single-icon'></span>
                                    <span class='reports-dashboard-more-option-single-text'>
                                      <span class="afe-icon-dropbox"></span>
                                      Dropbox
                                    </span>
                                  </div>
                                  <div (click)="openGoogleDrivePicker()" class='reports-dashboard-more-option-single'>
                                    <span class='reports-dashboard-more-option-single-text'>
                                      <span class="afe-icon-google-drive"></span>
                                      Google Drive
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <i class="afe-icon-moreOptions"></i>
                              <i class="afe-icon-moreOptions-hover"></i>
                              <span>More</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="yourSecureSection">
                        <ul>
                            <li>
                                <span class="afe-upload-help-options" (click)="openAFEInfoModal()">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99968 1.04134C14.9413 1.04134 18.958 5.05801 18.958 9.99967C18.958 14.9413 14.9413 18.958 9.99967 18.958C5.05801 18.958 1.04134 14.9413 1.04134 9.99967C1.04134 5.05801 5.05801 1.04134 9.99968 1.04134ZM9.99967 17.708C14.2497 17.708 17.708 14.2497 17.708 9.99967C17.708 5.74967 14.2497 2.29134 9.99968 2.29134C5.74968 2.29134 2.29134 5.74967 2.29134 9.99967C2.29134 14.2497 5.74967 17.708 9.99967 17.708Z" fill="#7A28A0"/>
                                        <path d="M10 8.54134C10.3417 8.54134 10.625 8.82467 10.625 9.16634L10.625 13.333C10.625 13.6747 10.3417 13.958 10 13.958C9.65833 13.958 9.375 13.6747 9.375 13.333L9.375 9.16634C9.375 8.82467 9.65833 8.54134 10 8.54134Z" fill="#7A28A0"/>
                                        <path d="M9.99967 5.83359C10.108 5.83359 10.2163 5.85859 10.3163 5.90026C10.4163 5.94193 10.508 6.00026 10.5913 6.07526C10.6663 6.15859 10.7247 6.24193 10.7663 6.35026C10.808 6.45026 10.833 6.55859 10.833 6.66693C10.833 6.77526 10.808 6.88359 10.7663 6.98359C10.7247 7.08359 10.6663 7.17526 10.5913 7.25859C10.508 7.33359 10.4163 7.39193 10.3163 7.43359C10.1163 7.51693 9.88301 7.51693 9.68301 7.43359C9.58301 7.39193 9.49134 7.33359 9.40801 7.25859C9.33301 7.17526 9.27468 7.08359 9.23301 6.98359C9.19134 6.88359 9.16634 6.77526 9.16634 6.66693C9.16634 6.55859 9.19134 6.45026 9.23301 6.35026C9.27468 6.24193 9.33301 6.15859 9.40801 6.07526C9.49134 6.00026 9.58301 5.94193 9.68301 5.90026C9.78301 5.85859 9.89134 5.83359 9.99967 5.83359Z" fill="#7A28A0"/>
                                    </svg>
                                    <span>How it works</span>
                                </span>
                            </li>
                            <li>
                                <span (click)="downloadOrViewSampleFile(true)" class="afe-upload-help-options">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M9.99987 16.8913C12.9415 16.8913 15.6832 15.1579 17.5915 12.1579C18.3415 10.9829 18.3415 9.00794 17.5915 7.83294C15.6832 4.83294 12.9415 3.09961 9.99987 3.09961C7.0582 3.09961 4.31654 4.83294 2.4082 7.83294C1.6582 9.00794 1.6582 10.9829 2.4082 12.1579C4.31654 15.1579 7.0582 16.8913 9.99987 16.8913Z" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>View sample</span>
                                </span>
                            </li>
                            <li>
                                <svg fill="none" height="14" viewBox="0 0 12 14" width="12" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M4.66667 9.66683C4.66667 8.93045 5.26363 8.3335 6 8.3335C6.73638 8.3335 7.33334 8.93045 7.33334 9.66683C7.33334 10.4032 6.73638 11.0002 6 11.0002C5.26363 11.0002 4.66667 10.4032 4.66667 9.66683Z"
                                      fill="#647087"></path>
                                    <path clip-rule="evenodd"
                                      d="M2.66667 5.66182V3.66683C2.66667 2.78277 3.01786 1.93493 3.64298 1.30981C4.2681 0.684685 5.11595 0.333496 6 0.333496C6.88406 0.333496 7.73191 0.684685 8.35703 1.30981C8.98215 1.93493 9.33334 2.78277 9.33334 3.66683V5.66182C10.4379 5.66182 11.3333 6.55725 11.3333 7.66182V11.6618C11.3333 12.7664 10.4379 13.6618 9.33334 13.6618H2.66667C1.5621 13.6618 0.666672 12.7664 0.666672 11.6618V7.66182C0.666672 6.55725 1.5621 5.66182 2.66667 5.66182ZM4.58579 2.25262C4.96086 1.87754 5.46957 1.66683 6 1.66683C6.53044 1.66683 7.03915 1.87754 7.41422 2.25262C7.78929 2.62769 8 3.1364 8 3.66683V5.66182H4V3.66683C4 3.1364 4.21072 2.62769 4.58579 2.25262ZM3.25124 6.99516C3.27814 6.99846 3.30554 7.00016 3.33334 7.00016C3.36113 7.00016 3.38853 6.99846 3.41544 6.99516H8.58457C8.61148 6.99846 8.63888 7.00016 8.66667 7.00016C8.69447 7.00016 8.72187 6.99846 8.74877 6.99516H9.33334C9.70153 6.99516 10 7.29363 10 7.66182V11.6618C10 12.03 9.70153 12.3285 9.33334 12.3285H2.66667C2.29848 12.3285 2.00001 12.03 2.00001 11.6618V7.66182C2.00001 7.29363 2.29848 6.99516 2.66667 6.99516H3.25124Z"
                                      fill="#647087" fill-rule="evenodd"></path>
                                </svg>
                                <span>Your data is secure</span>
                            </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    <div class="AFEListSection">
        <div class="container">
            <div class="row">
                <div class="col-sm-12" style="padding-left: 0; padding-right: 0;">
                    <div class="edit_file_heading">
                        <h3 class="card-title collapsed" (click)="logEditCollapsedState()" data-toggle="collapse" href="#ProcessedFileList">
                            <span></span>
                            View edited files</h3>
                        <div class="file_searchBox">
                            <div class="file_searchBox_outer">
                                <div class="file_search_icon">
                                    <img src="/assets/images/search_icon_new.svg" style="margin-top: -5px;" alt="">
                                </div>
                                <input placeholder="Search" [(ngModel)]="filterModel" (ngModelChange)="openAfeFileList($event)">
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ProcessedFileList" class="col-sm-12 subMenuSection collapse">
                    <div class="list_of_file_section">
                        <ng-container *ngIf="autoEditFilesList.length != 0">
                            <ul>
                                <ng-container *ngFor="let myIndex = index; let file of autoEditFilesList | filterByFileName: filterModel;">
                                    <div class="" [ngClass]="(myIndex === 0 && highlightFirstFile)? 'bodyHightLight' : ''"></div>
                                    <li *ngIf="file.status != 'OVER_LIMIT'" [ngClass]="myIndex === 0 && highlightFirstFile ? 'highlight-file-details' : ''">
                                        <div class="file_icons" *ngIf="file?.parentExtension !== 'tex'">
                                            <img src="../../../../assets/images/AEF_file_icon.svg" alt="">
                                        </div>
                                        <div class="file_icons" *ngIf="file?.parentExtension === 'tex'">
                                            <span class="file-icon-outer">
                                                <span class="afe-icon-fileUpload-tex"></span>
                                            </span>
                                        </div>
                                        <div class="file_name_text">
                                            <p title="{{getFileNameToBeShown(file)}}">{{getFileNameToBeShown(file)}}</p>
                                            <ol class="uploadFileDetails" *ngIf="file.id!='123'">
                                                <li>
                                                    {{file.process_start_time | date:'dd/MMM/yy'}} {{file.process_start_time | date:'shortTime'}}
                                                </li>
                                                <li>{{file.lang_code.toUpperCase() || 'EN'}}
                                                    <i style="margin-left: 3px;" *ngIf="file.lang_code === 'en'">({{file.language.toUpperCase()}})</i></li>
                                                <li *ngIf="file.readonly === false && file.status === 'SUCCESS'">Editable</li>
                                                <li *ngIf="file.readonly === true && file.status === 'SUCCESS'">Read only</li>
                                            </ol>
                                        </div>

                                        <div class="file_Errors" *ngIf="file.status === 'SUCCESS' && file.lang_code === 'es'">
                                            <span style="position: relative;top: -3px;display: inline-block;width: 10px;border-top: solid 2px #414E62;"></span>
                                            <p>Score</p>
                                        </div>

                                        <div class="file_Errors" *ngIf="file.status === 'SUCCESS' && file.lang_code == 'en' " (click)="scoreReport(file)">
                                            <span style="text-decoration: none; color: #111A27;font-size: 16px;font-weight: 600; cursor: default;" *ngIf="file.scoring_version == 'v1'">{{file.error_report?.language_score
                                                | number:'1.0-2'}}%</span>
                                            <span style="text-decoration: none; color: #111A27;font-size: 16px;font-weight: 600; cursor: default;" *ngIf="file.scoring_version == 'v2'">{{file.scoring_report?.total_score
                                                | number:'1.0-2'}}%</span>
                                            <p>Score</p>
                                        </div>
                                        <div class="file_Errors" *ngIf="file.status === 'SUCCESS' && file.errorCount != 0 && file.lang_code == 'en' && file.scoring_version == 'v2'"
                                            (click)="errorReport(file)">
                                            <span class="revision-count" style="text-decoration: none; color: #7A28A0;font-weight: 600;font-size: 16px;">{{file.scoring_report?.total_count||0}}</span>
                                            <p>Revisions</p>
                                        </div>
                                        <div class="file_Errors" *ngIf="file.status === 'SUCCESS' && file.errorCount != 0 && file.lang_code == 'en' && file.scoring_version == 'v1'"
                                            (click)="errorReport(file)">
                                            <span class="revision-count" style="text-decoration: none; color: #7A28A0;font-weight: 600;font-size: 16px;">{{file.error_report?.total_corrections+file.error_report?.total_suggestions}}</span>
                                            <p>Revisions</p>
                                        </div>
                                        <div class="file_Errors" [ngClass]="file.lang_code == 'es'? 'revisionWithES': '' " *ngIf="file.status === 'SUCCESS' && file.errorCount != 0 && file.lang_code == 'es'">
                                            <span class="revision-count" style="text-decoration: none !important; color: #414E62;font-weight: 600;font-size: 16px; cursor: default;">{{file.error_report?.total_corrections+file.error_report?.total_suggestions}}</span>
                                            <p>Revisions</p>
                                        </div>
                                        <div class="file_Errors disabled"
                                            *ngIf="file.status === 'SUCCESS' && file.errorCount === 0">
                                            <span>{{file.errorCount}}</span>
                                            <span><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
                                            <p>Revisions</p>
                                        </div>
                                        
                                        <div class="file_score"
                                            *ngIf="file.status === 'SUCCESS'">
                                            <span style="text-decoration: none; color: #111A27;font-size: 16px;font-weight: 600; cursor: default;"
                                                *ngIf="file.status != 'OVER_LIMIT'">{{file.credits}}</span>
                                            <span style="text-decoration: none; color: #111A27;font-size: 16px;font-weight: 600; cursor: default; "
                                                *ngIf="file.status === 'OVER_LIMIT'">0</span>
                                            <p>Credit<span *ngIf="file.credits !== 1" style="text-decoration: none;color: #414E62;font-size: 14px;font-weight: 400;">s</span> Used</p>
                                        </div>

                                        <!-- <div class="file_score" *ngIf="file.status === 'OVER_LIMIT' && file.status === 'SUCCESS'">
                                        </div> -->

                                        <div class="afeEditingMoeSection">
                                            <div class="afePowerModeTag" *ngIf="file.pipeline === 'advanced' && file.status === 'SUCCESS' && file.lang_code == 'en'">
                                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5583 0.358979C2.5883 0.255313 2.65056 0.164309 2.73575 0.099594C2.82095 0.0348791 2.92449 -6.31827e-05 3.03088 8.57665e-08H6.96909C7.04705 6.01901e-05 7.12388 0.0189249 7.19326 0.0550411C7.26264 0.0911574 7.32258 0.143492 7.36815 0.207738C7.41371 0.271983 7.44361 0.346302 7.45536 0.424575C7.46712 0.502849 7.4604 0.582839 7.43577 0.657961L5.68326 5.99964H9.43047C9.52266 5.99958 9.61301 6.0258 9.69124 6.07534C9.76947 6.12487 9.83244 6.19572 9.87297 6.27981C9.91349 6.36391 9.92996 6.45787 9.92048 6.551C9.91101 6.64413 9.87597 6.73269 9.81937 6.8066L2.9275 15.8061C2.8569 15.8987 2.75651 15.9632 2.64385 15.9884C2.5312 16.0135 2.41342 15.9977 2.31109 15.9436C2.20876 15.8896 2.12836 15.8007 2.08393 15.6926C2.0395 15.5845 2.03386 15.464 2.06799 15.3521L3.84117 9.49943H0.569499C0.492965 9.49948 0.417474 9.4814 0.349022 9.44663C0.280571 9.41187 0.221044 9.36137 0.175169 9.29915C0.129294 9.23693 0.0983353 9.1647 0.0847511 9.08821C0.0711669 9.01171 0.0753314 8.93305 0.0969141 8.85847L2.5583 0.358979Z" fill="#FDB933"/>
                                                </svg>
                                                <span>Power Mode</span>
                                            </div>
                                            <div class="afeLiteModeTag" *ngIf="file.pipeline === 'basic' && file.status === 'SUCCESS' && file.lang_code == 'en'">
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.731598 17L2.49502 15.2222L4.25843 17L6.02185 15.2222L7.78527 17L9.54869 15.2222L11.3121 17L13.5164 15.2222L15.2798 17" stroke="#111A27" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M15.2796 4.66169L14.3979 3.77281L12.6345 2.0044M12.6345 2.0044L4.69909 10.0448L3.81738 13.5506L7.34422 12.6617L15.7205 4.21725C15.8942 4.04217 16.032 3.83431 16.126 3.60553C16.22 3.37676 16.2684 3.13155 16.2684 2.88392C16.2684 2.63628 16.22 2.39108 16.126 2.1623C16.032 1.93352 15.8942 1.72566 15.7205 1.55058L15.6041 1.44303C15.2461 1.13871 14.7877 0.981207 14.32 1.00179C13.8522 1.02237 13.4091 1.21954 13.0789 1.55414L12.6345 2.0044Z" stroke="#111A27" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <span>Lite Mode</span>
                                            </div>
                                            <div class="afeLiteModeTag afeEditingModeText" *ngIf="file.pipeline !== 'basic' && file.pipeline !== 'advanced' && file.status === 'SUCCESS' && file.lang_code == 'en'">
                                                <span style="position: relative;top: 3px;display: inline-block;width: 10px;border-top: solid 2px #414E62;"></span>
                                                <p>Editing Mode</p>
                                            </div>
                                            <div class="afeLiteModeTag afeEditingModeText" *ngIf="file.status === 'SUCCESS' && file.lang_code === 'es'">
                                                <span style="position: relative;top: 3px;display: inline-block;width: 10px;border-top: solid 2px #414E62;"></span>
                                                <p>Editing Mode</p>
                                            </div>
                                        </div>

                                        <div class="file_download" *ngIf="file.status === 'SUCCESS'">
                                            <button (click)="file.id!='123' ? downloadFile(file) : downloadOrViewSampleFile()">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.7164 18.5412H7.28307C3.19141 18.5412 1.44141 16.7912 1.44141 12.6996V12.5912C1.44141 8.89124 2.89974 7.10791 6.16641 6.79957C6.49974 6.77457 6.81641 7.02457 6.84974 7.36624C6.88307 7.70791 6.63307 8.01624 6.28307 8.04957C3.66641 8.29124 2.69141 9.52457 2.69141 12.5996V12.7079C2.69141 16.0996 3.89141 17.2996 7.28307 17.2996H12.7164C16.1081 17.2996 17.3081 16.0996 17.3081 12.7079V12.5996C17.3081 9.50791 16.3164 8.27457 13.6497 8.04957C13.3081 8.01624 13.0497 7.71624 13.0831 7.37457C13.1164 7.03291 13.4081 6.77457 13.7581 6.80791C17.0747 7.09124 18.5581 8.88291 18.5581 12.6079V12.7162C18.5581 16.7912 16.8081 18.5412 12.7164 18.5412Z" fill="#7A28A0"/>
                                                    <path d="M10 13.0253C9.65833 13.0253 9.375 12.742 9.375 12.4003V1.66699C9.375 1.32533 9.65833 1.04199 10 1.04199C10.3417 1.04199 10.625 1.32533 10.625 1.66699V12.4003C10.625 12.7503 10.3417 13.0253 10 13.0253Z" fill="#7A28A0"/>
                                                    <path d="M9.99954 13.9585C9.84121 13.9585 9.68288 13.9002 9.55788 13.7752L6.76621 10.9835C6.52454 10.7419 6.52454 10.3419 6.76621 10.1002C7.00788 9.85853 7.40788 9.85853 7.64954 10.1002L9.99954 12.4502L12.3495 10.1002C12.5912 9.85853 12.9912 9.85853 13.2329 10.1002C13.4745 10.3419 13.4745 10.7419 13.2329 10.9835L10.4412 13.7752C10.3162 13.9002 10.1579 13.9585 9.99954 13.9585Z" fill="#7A28A0"/>
                                                </svg>
                                                Download
                                            </button>
                                        </div>

                                        <div class="file_download_fail" *ngIf="file.status === 'FAILED'">
                                            <button
                                                alt="Seems like there is a problem with processing the file. Write to support@trinka.ai for help. Our team will look into it and reply.">
                                                <img src="/assets/images/Icon_Info_redColor.svg">
                                            </button>
                                            <span>File processing failed</span>
                                        </div>

                                        <div class="file_download_fail" *ngIf="file.status === 'OVER_LIMIT'">
                                            <button
                                                alt="This feature is available only for files containing less than 25,000 words.">
                                                <img src="/assets/images/Icon_Info_redColor.svg">
                                            </button>
                                            <span>File processing failed</span>
                                        </div>
                                        <div class="fileUploadingLoader"
                                            *ngIf="(file.status !== 'FAILED' && file.status !== 'SUCCESS' && file.status !== 'OVER_LIMIT' && file.status !== 'WAITING_IN_QUEUE' && file.status !== 'ON_HOLD')">
                                            <svg class="spinner">
                                                <circle style="cx: 50%;cy: 50%;r: 50%;">
                                                  <animateTransform attributeName="transform" type="rotate" values="-90;810" keyTimes="0;1" dur="2s" repeatCount="indefinite"></animateTransform>
                                                  <animate attributeName="stroke-dashoffset" values="0%;0%;-157.080%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                                                  <animate attributeName="stroke-dasharray" values="0% 314.159%;157.080% 157.080%;0% 314.159%" calcMode="spline" keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite"></animate>
                                                </circle>
                                              </svg>
                                            <p>Your file is being proofread...</p>
                                        </div>


                                        <div class="inprogress_file file_queued_status" *ngIf="file.status === 'ON_HOLD'">

                                            <p style="text-decoration: underline; cursor: pointer; color: #0000ee;" *ngIf="(creditsDetails.credits + creditsDetails.purchased_credits === 0 ||
                                        (0 > (creditsDetails.credits + creditsDetails.purchased_credits )- file.credits))"
                                                (click)="addCredits()">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 4V8L10 10" stroke="#4D4D4D" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                                Add Credits to Process
                                            </p>

                                            <p *ngIf="file.status=='ON_HOLD'  && (creditsDetails.credits + creditsDetails.purchased_credits >= file.credits) "
                                                (click)="reUploadFile(file)" style="cursor: pointer;"> <svg width="16"
                                                    height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 4V8L10 10" stroke="#4D4D4D" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                                Edit this file now </p>
                                        </div>


                                        <div class="inprogress_file file_queued_status"
                                            *ngIf="file.status === 'WAITING_IN_QUEUE'">
                                            <p>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                                        stroke="#4D4D4D" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 4V8L10 10" stroke="#4D4D4D" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                                Queued
                                            </p>
                                        </div>


                                        <div class="file_delete"
                                            *ngIf="!(file.status !== 'FAILED' && file.status !== 'SUCCESS' && file.status !== 'OVER_LIMIT'  && file.status !== 'WAITING_IN_QUEUE') || (file.status === 'ON_HOLD')">
                                            <button (click)="deleteFileNewFlow(file)" *ngIf="file.id!='123'">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M7.08301 4.14199L7.26634 3.05033C7.39967 2.25866 7.49967 1.66699 8.90801 1.66699H11.0913C12.4997 1.66699 12.608 2.29199 12.733 3.05866L12.9163 4.14199" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M15.7087 7.61621L15.167 16.0079C15.0753 17.3162 15.0003 18.3329 12.6753 18.3329H7.32533C5.00033 18.3329 4.92533 17.3162 4.83366 16.0079L4.29199 7.61621" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M8.6084 13.75H11.3834" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M7.91699 10.417H12.0837" stroke="#7A28A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>

                                    </li>
                                </ng-container>
                            </ul>
                        </ng-container>

                        <ng-container *ngIf="autoEditFilesList.length === 0">
                            <ul class="no_file_search_section">
                                <li>
                                    <p>Your auto-edited files will appear here.</p>
                                </li>
                            </ul>
                        </ng-container>

                        <ng-container
                            *ngIf="(autoEditFilesList | filterByFileName: filterModel).length<=0 && autoEditFilesList.length>0">
                            <ul class="no_file_search_section">
                                <li>
                                    <p>Sorry, we couldn't find any file named "{{filterModel}}". Please try a different search.
                                    </p>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                    <span class="foot-section-ref" style="opacity: 0;"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="afe-page-footer">
        <div class="afe-page-footer-feedback">
            <button title="Feedback" (click)="openFeedBackModal()" class="afe-page-footer-feedback-btn">
                <span class="afe-message-text-icon"></span>
            </button>
        </div>
    </div>
</div>
