import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByInclusiveCategory'
})
export class FilterByInclusiveCategoryPipe implements PipeTransform {

  transform(items: any[], searchCategory: any): any[] {
    if (!items) return [];

    return items.filter(it => {
      if(it.suggestions){
        if (searchCategory.has(it.suggestions[0].error_category)) {
          it['node'].removeClass('tempTagRemove')
          return true;
        } else {
          it['node'].addClass('tempTagRemove')
        }
      }
    });
  }
}
