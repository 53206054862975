import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/Observable';
import { FilesList } from 'src/app/_interfaces/dashboard/file-list';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { WhyIChooseTrinka } from 'src/app/_interfaces/work-flow/WhyIChooseTrinka';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private dashboardCloudFileDetails = new BehaviorSubject<any>({});

  private dashboardLoaclFileDetails = new BehaviorSubject<File>(null);

  private userCreditDetails = new BehaviorSubject<any>({});

  private userPlanDetails = new BehaviorSubject<any>({});

  private userProfileDetails = new BehaviorSubject<any>({});

  private userFeatureDetails = new BehaviorSubject<any>({});

  constructor(public http: HttpClient) { }

  public getStoreUserCreditDetails(): any{
    return this.userCreditDetails.asObservable();
  }

  public storeUserCreditDetails(data): any{
    return this.userCreditDetails.next(data);
  }

  public getStoreUserPlanDetails(): any{
    return this.userPlanDetails.asObservable();
  }

  public storeUserPlanDetails(data): any{
    return this.userPlanDetails.next(data);
  }

  public getStoreUserProfileDetails(): any{
    return this.userProfileDetails.asObservable();
  }

  public storeUserProfileDetails(data): any{
    return this.userProfileDetails.next(data);
  }

  public getStoreUserFeatureDetails(): any{
    return this.userFeatureDetails.asObservable();
  }

  public storeUserFeatureDetails(data): any{
    return this.userFeatureDetails.next(data);
  }

  public getDashboardCloudFileDetails(): any {
    return this.dashboardCloudFileDetails.asObservable();
  }

  public setDashboardCloudFileDetails(data) {
    this.dashboardCloudFileDetails.next(data);
  }

  public getDashboardLocalFileDetails(): any {
    return this.dashboardLoaclFileDetails.asObservable();
  }

  public setDashboardLocalFileDetails(data) {
    return this.dashboardLoaclFileDetails.next(data);
  }


  getFilesByUser(userId: any): Observable<FilesList> {
    return this.http.get<FilesList>(environment.server_address + "/trinka/api/v1/user/" + userId + "/file")
  }

  getCreditsDetailsByUser(emailId: string): Observable<any> {
    return this.http.get<any>(environment.credit_service + "/credit/api/v1/user/" + emailId)
  }

  postCreateNewFile(userId: any): Observable<any[]> {
    return this.http.post<any[]>(environment.server_address, userId)
  }

  deleteFileByIdAutoEdit(fileId) {
    return this.http.delete(environment.autoedit_api + "/autoedit/v1/document/" + fileId)
  }

  reUploadOnholdFile(fileId) {
    return this.http.put(environment.autoedit_api + "/autoedit/v1/document/" + fileId, {})
  }

  deleteFileById(userId: string, fileId: string) {
    return this.http.delete(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + fileId)
  }

  restoreFileByIdAutoEdit(fileId: string, undo: boolean) {
    return this.http.patch(environment.autoedit_api + "/autoedit/v1/document/" + fileId, { undo: undo })
  }

  restoreFileById(userId: any, fileId: string, undo: boolean) {
    return this.http.patch(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + fileId, { undo: undo })
  }

  createNewFile(userId: any, folderId) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/file", folderId)
  }
  uploadFile(userId: any, FileObj: FormData) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/" + userId + "/file", FileObj)
  }

  downloadAutoEdit(fileid:any) {
    const file_download_autoedit = environment.autoedit_api+"/autoedit/v1/document/"+fileid+"/final/";
    return this.http.get<Blob>(file_download_autoedit)
  }

  download(userid: any, fileid: any, bool?) {
    const file_download = environment.server_address + "/trinka/api/v1/user/" + userid + "/file/" + fileid + "/export" + "?track_changes=" + bool;
    return this.http.get<Blob>(file_download, { observe: 'response', responseType: 'blob' as 'json' })
  }

  downloadFile(userid: any, fileid: any, plagarismType: any, user_decoded_id: any) {
    const file_download = environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/" + userid + "/file/" + fileid + "?plagiarismType=" + plagarismType + "&d_user_id=" + user_decoded_id;
    return this.http.get<Blob>(file_download, { observe: 'response', responseType: 'blob' as 'json' })
  }

  getUserDetails(user_id: any) {
    return this.http.get<any>(environment.auth_api + "/api/v1/user/" + user_id)
  }

  putUserDetails(user_id: any, data: any) {
    return this.http.put<any>(environment.auth_api + "/api/v1/user/" + user_id, data)
  }
  getUserPassword(user_id: any) {
    return this.http.get<any>(environment.auth_api + "/api/v1/password/reset/" + user_id)
  }
  updateUserPassword(password: any) {
    return this.http.put<any>(environment.auth_api + "/api/v1/user/password/reset", { password: password })
  }

  putUpdateFile(userId: any, fileObj: any): Observable<FileObject> {
    return this.http.put<FileObject>(environment.server_address + "/trinka/api/v1/user/" + userId + "/file/" + fileObj.file_id, fileObj)
  }


  getUserMultipleProfile(email_id: string): Observable<any> {
    return this.http.get<any>(environment.auth_api + "/api/v1/user/" + email_id + "/profilelist");
  }
  getSelectedProfileData(email_id: string): Observable<any> {
    return this.http.get<any>(environment.auth_api + "/api/v1/user/" + email_id + "/profiledetails")
  }
  putselectedUserProfile(email_id: string, profile_obj: any): Observable<any> {
    return this.http.put<any>(environment.auth_api + "/api/v1/user/" + email_id + "/profiledetails", profile_obj)
  }

  addToDictionary(dicTextVal: any): Observable<any> {
    return this.http.post(environment.server_address + "/trinka/api/v1/dictionary", dicTextVal)
    // return this.http.post("//localhost:9000/trinka/api/v1/dictionary", dicTextVal)
  }
  getDictVal() {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/dictionary")
    // return this.http.get<any>("//localhost:9000/trinka/api/v1/dictionary")
  }

  updateDictWord(updateWord: any): Observable<any> {
    return this.http.put<any>(environment.server_address + "/trinka/api/v1/dictionary", updateWord)
  }

  deleteDictVal(delWord: any): Observable<any> {
    return this.http.delete(environment.server_address + "/trinka/api/v1/dictionary?word=" + delWord)
  }

  restoreWord(word: any): Observable<any> {
    return this.http.post(environment.server_address + "/trinka/api/v1/dictionary/restore", { word: word })
  }

  featurePermission(): Observable<any> {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/permission")
  }
  uploadAutoEditFile(file: FormData): Observable<any> {
    return this.http.post<any>(environment.autoedit_api + "/autoedit/v1/document", file)
  }

  uploadAutoEditCloudFile(file: FormData): Observable<any> {
    return this.http.post<any>(environment.autoedit_api + "/autoedit/v1/cloud/document", file)
  }
  getAutoEditFileList(): Observable<any> {
    return this.http.get<any>(environment.autoedit_api + "/autoedit/v1/document");
  }

  getAutoEditCredits(): Observable<any> {
    return this.http.get<any>(environment.autoedit_api + "/api/v1/user/");
  }

  userVerifyOtp(otp, email_id): Observable<any> {
    return this.http.post<any>(environment.auth_api + "/api/v1/user/otp/verify", { otp: otp, email_id: email_id });
  }

  resendOtp(email_id): Observable<any> {
    return this.http.post<any>(environment.auth_api + "/api/v1/user/otp/send/", { email_id: email_id, source: 'TRINKA' });
  }

  resendVerifyMail(email_id): Observable<any> {
    return this.http.post<any>(environment.auth_api + "/api/v1/user/verifylink/send", { email_id: email_id, source: 'TRINKA' });
  }

  userVerifyMail(verifyKey): Observable<any> {
    return this.http.post<any>(environment.auth_api + "/api/v1/user/verifylink/verify", { verifyKey: verifyKey });
  }

  getReferalCode(referrer_email_id): Observable<any> {
    return this.http.get(environment.server_address + "/trinka/api/v1/" + referrer_email_id + "/referalcode");
  }

  sendReferEmailid(payload): Observable<any> {
    return this.http.post<any>(environment.server_address + "/trinka/api/v1/refer_trinka", payload);
  }

  getUserPaymentSubscription(userid) {
    return this.http.get(environment.server_address + "/trinka/api/v1/userplan/" + userid)
  }

  setTrinkaUseReason(role: any, userid) {
    var data = { user_role: role }
    return this.http.post(environment.server_address + "/trinka/api/v1/set_reason_to_choose_trinka/" + userid, data)
  }

  getTrinkaOnboardQusetions() {
    return this.http.get(environment.server_address + "/trinka/api/v1/onboard")
  }
  
  setTrinkaOnboardQusetions(userid: any, selection) {
    return this.http.post(environment.server_address + "/trinka/api/v1/set_onboard_user_selection/" + userid, selection)
  }

  setTrinkaUserRole(role: any, userid) {
    return this.http.post(environment.server_address + "/trinka/api/v1/set_reason_to_choose_trinka/" + userid, role)
  }

  setEmailStatusAutoEdit(notification_details) {
    return this.http.put(environment.autoedit_api + "/autoedit/v1/mailcontrol/", notification_details)
  }

  getEmailStatusAutoEdit(user_id) {
    return this.http.get(environment.autoedit_api + "/autoedit/v1/mailcontrol/" + user_id)
  }

  setEmailControlPublication(notification_details) {
    return this.http.post(environment.server_address + "/trinka/api/v1/publication/mail/", notification_details)
  }

  getEmailControlPublication() {
    return this.http.get(environment.server_address + "/trinka/api/v1/publication/mail/")
  }

  uploadPlagiarismFile(file: FormData): Observable<any> {
    return this.http.post<any>(environment.plagiarism_api + "/plagiarism/api/v1/upload_plagiarism_file", file)
  }

  plagFileWordCountAPI(file: FormData): Observable<any> {
    return this.http.post<any>(environment.plagiarism_api + "/plagiarism/api/v1/wordcount", file)
  }

  getPlagiarismFileList(user_id): Observable<any> {
    return this.http.get<any>(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/file_list/" + user_id + "/file");
  }

  getithenticateURL(user_id, file_id, plag_type, user_decoded_id, submission_id): Observable<any> {
    return this.http.get<any>(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/" + user_id + "/file/" + file_id + "/plag_check_type/" + plag_type + "/d_user/" + user_decoded_id +"/submission/"+ submission_id);
  }

  deleteFileByIdPlagiarism(user_id, file_id, user_decoded_id) {
    return this.http.delete(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/delete/" + user_id + "/file/" + file_id + "/d_user/" + user_decoded_id)
  }

  deleteUploadedFileByIdForPlagCheck(user_id, file_id, user_decoded_id) {
    return this.http.delete(environment.plagiarism_api + "/plagiarism/api/v1/delete/" + user_id + "/file/" + file_id + "/d_user/" + user_decoded_id)
  }

  restoreFileByIdPlagiarism(user_id, file_id: string, user_decoded_id) {
    return this.http.patch(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/restore/" + user_id + "/file/" + file_id + "/d_user/" + user_decoded_id, {})
  }

  reUploadOnholdPlagiarismFile(user_id, file_id, plagiarism_check_type, user_decoded_id) {
    return this.http.put(environment.plagiarism_api + "/plagiarism/api/v1/plagiarism/reupload/" + user_id + "/file/" + file_id + "/plagiarism_check_type/" + plagiarism_check_type + "/d_user/" + user_decoded_id, {})
  }

  deletePurgeFile(userId, file, source) {
    return this.http.put(environment.purge_service + "/trinka/v1/purge/user/" + userId + "/product/" + source + "/file/" + file, {});
  }

  deletePurgeFolder(userId, folder_id) {
    return this.http.put(environment.purge_service + "/trinka/v1/purge/user/" + userId + "/folder/" + folder_id, {});
  }

  deleteAccount(data) {
    return this.http.post(environment.server_address + "/trinka/api/v1/user/delete", data);
  }

  getEnagoUserFlag(user_id) {
    return this.http.get(environment.auth_api + "/api/v1/user/enago/" + user_id)
  }
  sharePlagCheckReport(payload) {
    return this.http.post(environment.auth_api + "/api/v1/share/plagcheck/report/mail", payload);
  }
  submitFeedbackCall(payload: any): Observable<any> {
    return this.http.post(`${environment.feedback_api}`, payload);
  }

  sendResendEmailid(email_id: string, first_name: string): Observable<any> {
    return this.http.post<any>(environment.auth_api + "/api/v1/domain/resend/email", { email_id: email_id, first_name: first_name});
  }

  getReferralRewards(emailId: string): Observable<any> {
    return this.http.get<any>(environment.server_address + "/trinka/api/v1/referral_reward/" + emailId);
  }

  getInstitutionalLogo(): Observable<any> {
    return this.http.get<any>(environment.auth_api + "/api/v1/user/domain/info");
  }

  getUsageStatsRequest(filter: any) {
    return this.http.post<any>(environment.analytics_dashboard_api + "/api/v1/b2c_userdata", filter);
  }

  getGrammarWordCountSummary(filter: any) {
    return this.http.post<any>(environment.analytics_dashboard_api + "/api/v1/gec_chart_reportsummary", filter);
  }

  getParaphraseWordCountSummary(filter: any) {
    return this.http.post<any>(environment.analytics_dashboard_api + "/api/v1/paraphraser_chart_reportsummary", filter);
  }

  getProofreadWordCountSummary(filter: any) {
    return this.http.post<any>(environment.analytics_dashboard_api + "/api/v1/afe_chart_reportsummary", filter);
  }

  getGeneratedReportCountSummary(filter: any) {
    return this.http.post<any>(environment.analytics_dashboard_api + "/api/v1/report_data_summary", filter);
  }
}
