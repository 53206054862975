import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-plag-report-plans',
  templateUrl: './plag-report-plans.component.html',
  styleUrls: ['./plag-report-plans.component.scss']
})
export class PlagReportPlansComponent implements OnInit {
  userId: any
  source_to_download: any
  @Input() plag_type_number: any;
  @Input() ithenticateUrl: any;
  @Input() turnitinUrl: any;
  @Input() score: any;
  @Input() filename: any;
  @Input() plansCTAToShow: any;
  @Input() file_id: any;
  @Input() card_id: any;
  @Input() plagiarism_free_score: any;
  @Input() total_free_score: any;
  @Input() source: any;
  @Input() free_score_reset_date: any;
  @Input() user_decoded_id: any;
  @Input() file: any;
  @Input() used_page_count: any;
  @Input() word_count: any;
  @Input() isPremiumPlusUser: any;
  @Input() plagiarism_free_page_count_scan: any
  @Input() stdPlagCheckUsedCredits: any
  @Input() userType: any

  @Output() onPlanClick = new EventEmitter<any>();
  @Output() GotoCard = new EventEmitter<{ fileId: any, card_id: any, plag_type_number: any }>();

  constructor(public amplitudeService: AmplitudeService,
    private sharedService: SharedService,
    private networkCalls: DashboardService) { }
  optionList: any = [{
    name: "Overall score",
    score: true,
    standard: true,
    advance: true
  },
  {
    name: "Matches from internet sources",
    score: true,
    standard: true,
    advance: true
  },
  {
    name: "Matches from paid publications",
    score: false,
    standard: false,
    advance: true
    }, {
      name: "Matches from open access journals",
      score: true,
      standard: true,
      advance: true
    }, {
    name: "List of top matched sources",
    score: false,
    standard: true,
    advance: true
  }, {
    name: "Detailed downloadable report",
    score: false,
    standard: true,
    advance: true
  }];

    PLAG_CHECK_FILE_UPLOAD_WROD_COUNT_LIMIT: number = 125000;
  CREDIT_CALCULATION_WORD_COUNT_BRACKET: number = 25000;
  STANDARD_CREDIT: number = 12;
  ADVANCE_CREDIT: number = 18;

  ngOnInit() {
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub);
    this.source_to_download = this.source == 'sample_card' ? true : false
    // this.optionList[2].score = this.userType !== 'BASIC';
    //console.log(this.turnitinUrl,this.plag_type_number,this.plansCTAToShow)
  }

  downloadFile(file, plag_type, is_sample, url, filename, report_url) {
    let report_type = plag_type == 2 ? 'advance check' : 'standard check'
    this.amplitudeService.logAmplitude('Plag_view_report', {
      'Report Type': report_type
    });
    // if (is_sample) {
    //   let a = document.createElement("a");
    //   document.body.appendChild(a);
    //   a.href = url;
    //   a.download = filename;
    //   a.click();
    //   document.body.removeChild(a);
    // }
    // else {
      // if (report_url) {
      //   let a = document.createElement("a");
      //   document.body.appendChild(a);
      //   a.href = atob(report_url);
      //   a.download = filename;
      //   a.click();
      //   document.body.removeChild(a);
      // }
      // else {
        
      //   this.sharedService.setPlagarismData(file.file_id, this.userId, plag_type);
      //   this.sharedService.downloadPlagiarismFile(this.user_decoded_id);
      // }
      let submission_id = plag_type == 2 ? file.submission_id : file.turnitine_submission_id;
      let download_url = plag_type == 2 ? file.ithenticate_report_pdf_url : file.turnitine_report_pdf_url;

      this.networkCalls.getithenticateURL(this.userId, file.file_id, plag_type, file.user_id, submission_id).subscribe(result => {
        // window.open(result.data, "_blank");
        let report_url = 'plagiarism-checker/report/' + plag_type + '/' + btoa(encodeURI(filename)).replace(/=/gi, "") +"/"+ btoa(encodeURIComponent(result.data));
        //console.log(report_url);
        window.open(report_url.replace(/=/gi, ""),"_blank");
      }, error => {
        this.sharedService.errorHandller(error);
      })
    // }

  }
  viewReport(filename, download_url, plag_type) {
    event.stopPropagation()
    window.open('plagiarism-checker/report/' + plag_type + '/' + btoa(encodeURI(filename)).replace(/=/gi, "") + '/' + download_url);
    if (plag_type == 3) {
      this.amplitudeService.logAmplitude('Plag_standard_report', {
        'from': 'report card',
        'Report Type': 'standard check'
      });
    }
    if (plag_type == 2) {
      this.amplitudeService.logAmplitude('Plag_advance_report', {
        'from': 'report card',
        'Report Type': 'advance check'
      });
    }
  }

  highlightScore() {
    this.onPlanClick.emit('view_score');
  }

  generatePlagiarismReport(plag_type: number) {
    this.onPlanClick.emit(plag_type)
  }
  goToCard(fileId, card_id,plag_type_number) {

    this.GotoCard.emit({ fileId: fileId, card_id: card_id , plag_type_number:plag_type_number})
  }

  getPageOrCreditCount() {
    const perPageCount = 250;
    let str = ''
    if (this.used_page_count) str = `${this.used_page_count} Page${this.used_page_count > 1 ? 's' : ''}`;
    else if (this.stdPlagCheckUsedCredits) str = `${this.stdPlagCheckUsedCredits} Credits`;
    else if (this.word_count && Math.ceil(this.word_count / perPageCount) <= this.plagiarism_free_page_count_scan) str = `${Math.ceil(this.word_count / perPageCount)} Page${Math.ceil(this.word_count / perPageCount) > 1 ? 's' : ''}`;
    else if (this.word_count && Math.ceil(this.word_count / perPageCount) > this.plagiarism_free_page_count_scan) str = '12 Credits';
    else str = '12 Credits';
    return str;
  }

  getPageCount(noOfWords: number) {
    const perPageCount = 250;
    if (noOfWords) return Math.ceil(noOfWords / perPageCount) 
    else return 0;
  }

  getStandardPlagCheckCreditCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.STANDARD_CREDIT;
      return  credits > 1 ? `${credits} credits` : `${credits} credit`;
    }
    else {
      return `0 credit`;
    }
  }

  getAdvanceCreditCount(noOfWords: number){
    if (noOfWords){ 
      let credits = Math.ceil(noOfWords / this.CREDIT_CALCULATION_WORD_COUNT_BRACKET) * this.ADVANCE_CREDIT;
      return  credits > 1 ? `${credits} Credits` : `${credits} Credit`;
    }
    else {
      return `0 Credit`;
    }
  }

}
