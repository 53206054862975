import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DashbaordRoutingModule } from './dashbaord-routing.module';
import { DashbaordComponent } from './dashbaord.component';
import { MainComponent } from 'src/app/components/dashboard/main/main.component';
// import { MydriveComponent } from 'src/app/components/dashboard/mydrive/mydrive.component';
import { SidebarComponent } from 'src/app/components/dashboard/sidebar/sidebar.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModuleList } from 'src/app/material-modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from 'src/app/_guards/auth.service';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service'
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from 'w-ng5';
import { ShareWidgetComponent } from 'src/app/components/dashboard/share-widget/share-widget.component';
import { LanguagePreferencesGlobalComponent } from 'src/app/components/language-preferences-global/language-preferences-global.component';
import { AuthInterceptor } from 'src/app/_services/interceptors/token.interceptor'
import { RenameFileComponent } from 'src/app/components/dashboard/mydrive/rename-file/rename-file.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SelectProfileModalComponent } from '../../components/select-profile-modal/select-profile-modal.component';
import { MyDictionaryComponent } from '../../components/dashboard/my-dictionary/my-dictionary.component';
import { UpdateDictionaryWordComponent } from 'src/app/update-dictionary-word/update-dictionary-word.component';
import { FilterByWordPipe } from 'src/app/_pipes/filter-by-word.pipe';
import { SortCreatedBy } from 'src/app/_pipes/sort-by-created-at.pipe';
import { SortByPipe } from 'src/app/_pipes/sort-by.pipe';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AutoEditComponent } from '../../components/dashboard/auto-edit/auto-edit.component';
import { ErrorReportComponent } from 'src/app/components/dashboard/error-report/error-report.component';
import { ScoreComponent } from 'src/app/components/dashboard/score/score.component';
import { ManageProfileComponent } from 'src/app/components/dashboard/manage-profile/manage-profile.component';
import { VerifyEmailComponent } from 'src/app/components/dashboard/verify-email/verify-email.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { SharedModule } from '../shared/shared.module';
import { CreditDetailsComponent } from 'src/app/components/dashboard/credit-details/credit-details.component';
import { PurgePlanComponent } from 'src/app/components/dashboard/purge-plan/purge-plan.component';
import { PaymentTransactionComponent } from 'src/app/components/dashboard/payment-transaction/payment-transaction.component';
import { AdvanceSettingsComponent } from 'src/app/components/dashboard/auto-edit/advance-settings/advance-settings.component'
import { DeleteAccountComponent } from 'src/app/components/dashboard/delete-account/delete-account.component';
import { PlagiarismCheckComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-check.component';
import { PlagiarismReportGenerateComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-report-generate/plagiarism-report-generate.component';
import { PlagiarismReportComparisonComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-report-comparison/plagiarism-report-comparison.component';
import { PlagiarismHelpToChooseReportModalComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-help-to-choose-report-modal/plagiarism-help-to-choose-report-modal.component';
import { PlagiarismScoreOnlySampleReportModalComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-score-only-sample-report-modal/plagiarism-score-only-sample-report-modal.component';
import { PlagiarismTrinkaSampleReportModalComponent } from 'src/app/components/dashboard/plagiarism-check/plagiarism-trinka-sample-report-modal/plagiarism-trinka-sample-report-modal.component';
import { PlagiasrimIthenticateSampleReportModalComponent } from 'src/app/components/dashboard/plagiarism-check/plagiasrim-ithenticate-sample-report-modal/plagiasrim-ithenticate-sample-report-modal.component';
import { ShowMyDriveFilesModalComponent } from 'src/app/components/dashboard/show-my-drive-files-modal/show-my-drive-files-modal.component';
import { DashboardFileUploadSelectionModalComponent } from 'src/app/components/dashboard/dashboard-file-upload-selection-modal/dashboard-file-upload-selection-modal.component';
import { ReferenceQualityStartComponent } from 'src/app/components/dashboard/reference-quality/reference-quality-start/reference-quality-start.component';
import { FilterByFileNamePipe } from 'src/app/_pipes/filter-by-file-name.pipe';
import { CustomPaymentTransactionDatePipe } from 'src/app/_pipes/custom-payment-transaction-date.pipe';
import { JournalFinderToolComponent } from 'src/app/components/journal-finder/journal-finder-tool/journal-finder-tool.component';
import { PlagCheckToolPageComponent } from 'src/app/components/plagiarism-check/plag-check-tool-page/plag-check-tool-page.component';
import { PlagCheckExistingUserOnboardingModalComponent } from 'src/app/components/plagiarism-check/plag-check-existing-user-onboarding-modal/plag-check-existing-user-onboarding-modal.component';
// import { ChangePaymentMethodComponent } from 'src/app/components/dashboard/change-payment-method/change-payment-method.component';
import { SavedCardsComponent } from 'src/app/components/dashboard/saved-cards/saved-cards.component';
import { DashboardLoaderSectionWithTextComponent } from 'src/app/components/dashboard/dashboard-loader-section-with-text/dashboard-loader-section-with-text.component';
import { FileLoaderComponent } from 'src/app/components/dashboard/auto-edit/file-loader/file-loader.component';
import { AutoEditUploadedFileComponent } from 'src/app/components/dashboard/auto-edit-uploaded-file/auto-edit-uploaded-file.component';
import { AfeSettingsModalComponent } from 'src/app/components/dashboard/auto-edit/afe-settings-modal/afe-settings-modal.component';
import { DeleteConfirmationModalComponent } from 'src/app/components/dashboard/auto-edit/delete-confirmation-modal/delete-confirmation-modal.component';
import { AfeInfoModalComponent } from 'src/app/components/dashboard/auto-edit/afe-info-modal/afe-info-modal.component';
import { FeedbackModalComponent } from 'src/app/components/dashboard/auto-edit/feedback-modal/feedback-modal.component';
import { MessageModalComponent } from 'src/app/components/dashboard/message-modal/message-modal.component';
import { LanguageAlertModalComponent } from 'src/app/components/language-alert-modal/language-alert-modal.component';
import { InstructionFeedbackModalComponent } from 'src/app/components/editor/instruction-feedback-modal/instruction-feedback-modal.component';
import { ReferralRewardModalComponent } from 'src/app/components/dashboard/referral-reward-modal/referral-reward-modal.component';
import { AnalyticsDashboardComponent } from 'src/app/components/dashboard/analytics-dashboard/analytics-dashboard.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import { AnalyticsDashboardLearnMoreComponent } from 'src/app/components/dashboard/analytics-dashboard-learn-more/analytics-dashboard-learn-more.component';
@NgModule({
  declarations: [
    DashbaordComponent,
    MainComponent,
    // MydriveComponent,
    ManageProfileComponent,
    SidebarComponent,
    ShareWidgetComponent,
    PlagiarismReportGenerateComponent,
    PlagiarismReportComparisonComponent,
    FilterByWordPipe,
    SortCreatedBy,
    LanguagePreferencesGlobalComponent,
    RenameFileComponent,
    SelectProfileModalComponent,
    MyDictionaryComponent,
    UpdateDictionaryWordComponent,
    SortByPipe,
    AutoEditComponent,
    AutoEditUploadedFileComponent,
    AfeSettingsModalComponent,
    DeleteConfirmationModalComponent,
    ErrorReportComponent,
    CreditDetailsComponent,
    ScoreComponent,
    VerifyEmailComponent,
    PurgePlanComponent,
    PaymentTransactionComponent,
    AdvanceSettingsComponent,
    PlagiarismCheckComponent,
    PlagiarismHelpToChooseReportModalComponent,
    PlagiarismScoreOnlySampleReportModalComponent,
    PlagiarismTrinkaSampleReportModalComponent,
    PlagiasrimIthenticateSampleReportModalComponent,
    DeleteAccountComponent,
    PlagiarismCheckComponent,
    ShowMyDriveFilesModalComponent,
    DashboardFileUploadSelectionModalComponent,
    DashboardLoaderSectionWithTextComponent,
    ReferenceQualityStartComponent,
    CustomPaymentTransactionDatePipe,
    JournalFinderToolComponent,
    PlagCheckToolPageComponent,
    PlagCheckExistingUserOnboardingModalComponent,
    // ChangePaymentMethodComponent,
    SavedCardsComponent,
    FileLoaderComponent,
    AfeInfoModalComponent,
    FeedbackModalComponent,
    MessageModalComponent,
    LanguageAlertModalComponent,
    InstructionFeedbackModalComponent,
    ReferralRewardModalComponent,
    AnalyticsDashboardComponent,
    AnalyticsDashboardLearnMoreComponent
  ],
  imports: [
    CommonModule,
    DashbaordRoutingModule,
    StorageServiceModule,
    NgxSpinnerModule,
    MaterialModuleList,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    FlexLayoutModule,
    PipesModule,
    NgbModule,
    NgOtpInputModule,
    SharedModule,
    FusionChartsModule
    // ShareWidgetComponent

  ], 
  providers: [
    AuthService,
    AuthGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe
  ], entryComponents: [
    ShareWidgetComponent,
    LanguagePreferencesGlobalComponent,
    SelectProfileModalComponent,
    RenameFileComponent,
    UpdateDictionaryWordComponent,
    ErrorReportComponent,
    CreditDetailsComponent,
    ScoreComponent,
    VerifyEmailComponent,
    PurgePlanComponent,
    PaymentTransactionComponent,
    AdvanceSettingsComponent,
    PlagiarismReportGenerateComponent,
    PlagiarismReportComparisonComponent,
    PlagiarismHelpToChooseReportModalComponent,
    PlagiarismScoreOnlySampleReportModalComponent,
    PlagiarismTrinkaSampleReportModalComponent,
    PlagiasrimIthenticateSampleReportModalComponent,
    DeleteAccountComponent,
    ShowMyDriveFilesModalComponent,
    DashboardFileUploadSelectionModalComponent,
    DashboardLoaderSectionWithTextComponent,
    PlagCheckExistingUserOnboardingModalComponent,
    // ChangePaymentMethodComponent,
    SavedCardsComponent,
    FileLoaderComponent,
    AfeSettingsModalComponent,
    DeleteConfirmationModalComponent,
    AfeInfoModalComponent,
    FeedbackModalComponent,
    MessageModalComponent,
    LanguageAlertModalComponent,
    InstructionFeedbackModalComponent,
    ReferralRewardModalComponent,
    AnalyticsDashboardLearnMoreComponent
  ],
  exports:[CustomPaymentTransactionDatePipe]
})
export class DashbaordModule { }
