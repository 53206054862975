import { AfterViewInit, Component, ElementRef, ViewChild, OnDestroy, Renderer2, HostListener, ChangeDetectorRef } from '@angular/core';
import { OnlineEditorService } from 'src/app/_services/networkcalls/online-editor.service';
import { SharedService } from 'src/app/_services/shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { WebsocketProvider } from 'y-websocket';
import { environment } from 'src/environments/environment';
import { GetMetaService } from '../../../_services/get-meta.service';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { UpgradeToPremiumComponent } from 'src/app/components/editor/upgrade-to-premium/upgrade-to-premium.component';
import { MatDialog, MatDialogConfig, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ConnectionService } from 'src/app/_socket/connection.service';
import { SaveNewFileDetailsModalComponent } from './save-new-file-details-modal/save-new-file-details-modal.component';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModuleCheck } from 'src/app/_enums/module-check.enum';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { FileObject } from 'src/app/_interfaces/editor/file';
import { DomSanitizer } from '@angular/platform-browser';
import { EditorAdvancePlagCheckCreditModalComponent } from '../editor-advance-plag-check-credit-modal/editor-advance-plag-check-credit-modal.component';

declare const trinkaSDK: any;
declare const Trinka_ENUMS: any;
declare const CKEDITOR: any

@Component({
  selector: 'app-root',
  //   template: `
  //   <div class="quillEditorSection" style="height: 100%; white-space:break-spaces; width: calc(100% - 395px);">
  //     <div #quillEditor style="padding: 10px 0% 100px"></div>
  //     <!-- <div id="counter">Word Count: 0</div> -->

  //   </div>
  // `,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
@Injectable()
export class MainComponentNew implements AfterViewInit, OnDestroy {
  paymentDetails: any;
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    // Apply your styles when the component is focused
    // this.applyCustomStyles();
  }
  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHandler(event: BeforeUnloadEvent): Promise<any> {
    this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
    await trinkaSDK.destroy();
    this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);
    this.currentSelectedModule = 'none';
    this.subjectiveBehaviorSharedService.moduleChange.next('none');
    this.moduleChangeSubscription.unsubscribe();
    this.debounceSaveFile(100);
  }

  @ViewChild('quillEditor', { static: true }) quillEditor!: ElementRef;
  quill: any; // Declare quill as a class property
  userId: string;
  documentId: string;
  ydoc: any;
  ytext: any;
  wsOpts: any;
  quillBinding: any;
  docTypeObject: any;
  private updateTimeout: any;
  dialogLimitOver: any = null;
  moduleChangeSubscription: any;
  styleGuideDispay = {
    "NONE": "None",
    "AMA": "AMA (11th ed)",
    "APA": "APA (7th ed)",
    "ACS": "ACS (2nd ed)",
    "AGU": "AGU (2017 ed)",
    "IEEE": "IEEE",
  }

  styleGuideLegalDispay = {
    "Redbook (3rd ed)-Legal": "USA-The Redbook (3rd ed.)",
    "SCI stereotypical language": "India-SCI Gender Handbook",
    "NONE": "Common Style"
  }
  currentSelectedModule: any;

  enabledModule = "";
  fileHolder = new FileObject();
  viewerEnabled: boolean = false;
  plagCheckReportUrl: any = "";
  isScanCalculated: boolean = false;
  noOfScanRequired: number = 0;
  avaialbleScan: number = 0;
  reportHistoryTab: boolean = true;
  sample_file_id = environment.plag_check_sample_report_id;
  userLimitExhausted = false;
  lastSavedTime: Date = new Date();
  sampleCard = {
    "file_id": this.sample_file_id,
    "submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
    "turnitine_submission_id": "47be6e58-9b4c-4c44-9eac-18c866b98e09",
  };
  creditsDetails: any;
  userData: any;
  savingSection = false;
  interval = null;
  languageString;
  wordCountSections = [
    { text: "word_count", value: 0, displayText: 'Words' },
  ]
  isSamplePlagCheckReport: boolean = false;
  plagFileWordCount: number = 0;
  plagCheckFileList: any = [];
  isGeneratingPlagCheckReport: boolean = false;
  isAdvancePlagCheckReport: boolean = false;
  selectedLanguageCode: string[] = ['en'];
  defaultLanguageCode: string[] = [];
  languageCodeOptions = [{
    "code": "en",
    "value": "English (EN)",
    "isSelected": false,
    "disabled": false
  },
  // {
  //   "code": "de",
  //   "value": "German (DE)",
  //   "isSelected": false,
  //   "disabled": false
  // },
  {
    "code": "es",
    "value": "Spanish (ES)",
    "isSelected": false,
    "disabled": false
  }];
  saving: boolean = false;
  editorContent: string = '';
  preBody: string = '';
  postBody: string = '';
  instructionModelToSend: string = '';
  instructionToSend: string = '';
  styleGuide: string = 'NONE';
  SPECIAL_SPACES = /[\u200B-\u200D\uFEFF]/g;
  COMMENT_HOLDER: string = "<div comment-holder=\"true\"><span start=\"true\" comment=\"_cmnt1\"><span></span></span><span end=\"true\" comment=\"_cmnt1\"></span></div>";
  COMMENT_DIV_REGEX: RegExp = /<div\s+id="_cmnt[0-9]+"[^><]*>.*<\/div>/g;
  COMMENT_DIV_PLACEHOLDER_REGEX = /<div data-placeholder>(.*?)<\/div>/;
  saveFileTimeout: any;
  scriptLoaded: boolean = false;

  constructor(private networkCalls: OnlineEditorService,
    public sharedService: SharedService,
    public amplitudeService: AmplitudeService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public metaString: GetMetaService,
    private changeDetector: ChangeDetectorRef,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private socketService: ConnectionService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private dashboardService: DashboardService,
    private _snackBar: MatSnackBar
  ) {

    this.removeCKeditor('cke_editor1').then((ckEditor: HTMLElement) => {
      ckEditor.remove();
    });
  }

  async removeCKeditor(editorId: string) {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const ckEditorElement = document.getElementById(editorId);
        if(ckEditorElement) {
          resolve(ckEditorElement);
          clearInterval(interval);
        }
      }, 1000);
    });
  }


  ngOnInit() {
    //Need to check the delay to load the CDN
      // setTimeout(() => {
      console.log('now coming here');
      this.scriptLoaded = true;

        if (document.querySelector('div.ck-editor__editable')) {
          document.querySelector('div.ck-editor__editable').id = 'ccEditor';
        }
        let socketUrl;
        let paraphraserSocketUrl;
        this.route.params.subscribe(params => {
          this.documentId = params['id'];
          //this.documentId = "690b31d2-da58-41fa-96e0-b58d2cda685d"       //hardcode
          this.sharedService.setCurrentFileAndUserId(this.userId, this.documentId);
          //To initialize the grammar JS code
          socketUrl = this.socketService.socketURLForSDK(this.documentId);
          paraphraserSocketUrl = this.socketService.socketURLForParaphraser(this.documentId);
        });
    
        
        let consistencyUrl = `${environment.server_address}/trinka/api/v1/consistency`;
        // let consistencyUrl = 'http://3.208.173.39:7500/api/v1/consistency'; //NOTE: Please use this when using on localhost

        function getCookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        }

        this.moduleChangeSubscription = this.subjectiveBehaviorSharedService.getModuleChange.subscribe(async (module) => {
          if (this.currentSelectedModule === module) return;
          this.currentSelectedModule = module;
          switch (module) {
            case 'grammar':
              this.enabledModule = '';
              // this.quill.history.clear();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
              await trinkaSDK.destroy();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);
              trinkaSDK.initializeModule('grammar', {}, {
                url: socketUrl,
                alertCountChangeEvent: (editorId, count) => {
                },
                trinkaEditorConfig: this.sharedService.grammarModuleConfig,
                whitelistEditors: ['div.ck-editor__editable']
              },
                {
                  counter: false,
                  scanEditorOn: Trinka_ENUMS.ON_PAGE_LOAD,
                  inlineAlerts: false
                });

              let editorID = document.querySelector('div.ck-editor__editable').getAttribute('trinka-editor-id');
              trinkaSDK.initializedModule.dockRightPanelForTrinkaEditor(editorID);
              break;

            case 'paraphraser':
              this.enabledModule = '';
              // this.quill.history.clear();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
              await trinkaSDK.destroy();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);

              trinkaSDK.initializeModule('paraphraser', {}, {
                url: socketUrl,
                paraphraserSocketURL: paraphraserSocketUrl,
                sentencesAPI: `${environment.paraphraser_address}/paraphraser/v2/sentences`,
                feedbackAPI: `${environment.paraphraser_address}/feedback/sentence`,
                // sentencesAPI: 'https://paraphraser.trinka.ai/paraphraser/marketing/sentences',
                // feedbackAPI: 'https://paraphraser.trinka.ai/feedback/sentence',
                isCloudEditor: true,
                langCode: this.sharedService.grammarModuleConfig.langCode // taking langCode same as grammar config
              },
                {
                  counter: false,
                  scanEditorOn: Trinka_ENUMS.ON_PAGE_LOAD,
                  inlineAlerts: false
                });
              break;

            case 'consistencyChecker':
              // this.quill.history.clear();
              if (document.querySelector('div.ck-editor__editable') && document.querySelector('div.ck-editor__editable').getAttribute('id') !== 'ccEditor') document.querySelector('div.ck-editor__editable').id = 'ccEditor';
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
              await trinkaSDK.destroy();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);
              this.enabledModule = '';
              trinkaSDK.initializeModule('consistencyChecker', {}, {
                url: consistencyUrl,
                editorId: 'ccEditor',
                token: `Bearer ${this.storage.get(this.metaString.getMeta())['token']}`,
                wips: `wips=${getCookie('wips').split('"')[1]}`,
                language: this.sharedService.renameFile.language,
                file_id: this.documentId,
                langCode: this.sharedService.grammarModuleConfig.langCode // taking langCode same as grammar config
              },
                {
                  counter: false,
                  scanEditorOn: Trinka_ENUMS.ON_PAGE_LOAD,
                  inlineAlerts: false
                });
              let newEditorID = document.querySelector('div.ck-editor__editable').getAttribute('trinka-editor-id');
              trinkaSDK.initializedModule.dockRightPanelForCCTrinkaEditor(newEditorID);
              break;

            case 'plagCheck':
              // this.quill.history.clear();
              await trinkaSDK.destroy();
              this.enabledModule = 'PC';
              break;

            case 'none':
              // this.quill.history.clear();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
              await trinkaSDK.destroy();
              this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);
              break;
          }
        })
      // }, 1000)


    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    
    this.dashboardService.getStoreUserPlanDetails().subscribe(resp => {
      if (Object.keys(resp).length > 0) {
        this.paymentDetails = resp;
      }
    });

    document.addEventListener('limitExhausted', (event: CustomEvent) => {
      if (event.detail) {
        if (event.detail.isLimitExhausted && !this.dialogLimitOver) {
          this.dialogLimitOver = this.dialog.open(UpgradeToPremiumComponent, {
            id: 'premium-pop-up',
            data: {
              "isPremium": this.paymentDetails["is_premium"]
            }
          })
        }
      }
    });

    document.addEventListener('paraphraserFeedbackAPIEvent', (event: CustomEvent) => {
      this._snackBar.open('Thank you for your feedback!', '', {
        duration: 1000
      });
    });

    document.addEventListener('amplitude-event-from-SDK', (event: CustomEvent) => {
      if (event.detail.eventName === "grammar_accept" || event.detail.eventName === "grammar_reject" || event.detail.eventName === "grammar_ignore" || event.detail.eventName === "add_to_dictionary" || event.detail.eventName === "consistency_accept") event.detail.amplitudeEventObj = { ...event.detail.amplitudeEventObj, 'fileID': this.documentId };
      event.detail.amplitudeEventObj = { ...event.detail.amplitudeEventObj, 'editor': 'V1' };
      this.amplitudeService.logAmplitude(event.detail.eventName, event.detail.amplitudeEventObj)
    });

    document.addEventListener('redirect-to-premium-upgrade-page', (event: CustomEvent) => {
      let data = {
        "user_id": this.userId
      };
      let userData = btoa(encodeURIComponent(JSON.stringify(data)));
      let redirct_url = environment.payment_fe;
      window.open(`${redirct_url}/user/${userData}`, "_blank");
    });
  }
  ngAfterViewInit(): void {
    // const quillElement = this.quillEditor ? this.quillEditor.nativeElement : null;
    // const container = document.getElementById('counter');
    setTimeout(() => {
      if(document.querySelector('div.ck-editor__editable')) document.querySelector('div.ck-editor__editable').id = 'ccEditor';
    }, 3000);
    // quillElement.style.height = '100%';
    // setTimeout(() => {
    //   if (quillElement && quillElement.querySelector('.ql-editor')) {
    //     quillElement.querySelector('.ql-editor').id = 'ccEditor';
    //   }
    // }, 1500);
    setTimeout(() => {
      this.loadFileFromDB();
    }, 500);
    if(document.querySelector('div.ck-editor__editable'))document.querySelector('div.ck-editor__editable').id = 'ccEditor';
    // Commenting all quill related code
    // TODO: Remove code once application is stable with CKEditor5
    // quillElement.querySelector('.ql-editor').id = 'ccEditor';
    /*if (quillElement) {
      
      //Load file from DB
      // setTimeout(() => {
      //   this.loadFileFromDB();
      //   // container.innerText = "Word Count: "+document.getElementsByClassName('ql-editor')[0].textContent.trim().split(" ").length;
      // }, 500);
      // Yjs integration with Quill
      // this.ytext = this.ydoc.getText('quillEditor');
    }
    // else {
    //   console.error('Quill editor element not found.');
    // }
    this.applyQuillStyles();
    // Create a Y-websocket provider and connect to a Yjs server
    // /const update = this.ydoc.encodeStateAsUpdate();
    const yWebSocketProvider = new WebsocketProvider(
      environment.yjs_server, // Replace with your Yjs server URL
      this.documentId, // Replace with your room name
      this.ydoc
    );
    this.quill.on('text-change', (delta, oldDelta, source) => {
      // container.innerText = "Word Count: "+document.getElementsByClassName('ql-editor')[0].textContent.trim().split(" ").length;
      // let snippetText = document.getElementsByClassName('ql-editor')[0].textContent.trim().slice(0, 100);
      // this.ydoc.getMap('metadata').set('snippet', snippetText);
    })
    // Event listener for Yjs document changes
    this.ydoc.on('update', update => {
      // The Y-WebsocketProvider automatically sends updates to the server
    });*/
  }

  async ngOnDestroy(): Promise<any> {
    // Perform cleanup operations, such as hiding the item
    // For example, you can use a service or directly manipulate the DOM to hide the item

    // For demonstration purposes, assuming you have a service to control the visibility:
    // this.someVisibilityService.hideItem();
    // Find the element with the id "ddc" anywhere in the DOM and remove it - this is for removing the trinka js popup, use the same approach for paraphraser and cc
    const ddcElement = document.getElementsByTagName('trinka-popup')[0];
    // Check if the element exists before attempting to remove it
    if (ddcElement) {
      // Use Renderer2 to remove the element from the DOM
      this.renderer.removeChild(ddcElement.parentElement, ddcElement);
    }

    // if(document.querySelector('trinka-popup')) document.querySelector('trinka-popup').remove();
    // if (document.querySelector('trinka-extension')) document.querySelector('trinka-extension').remove();
    // this.sharedService.trinkaParaphraserObject = null;
    // this.sharedService.trinkaGrammarObject = null;
    this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(true);
    // window.location.reload();
    await trinkaSDK.destroy();
    this.subjectiveBehaviorSharedService.disableRightPanelMenuOptions.next(false);
    this.subjectiveBehaviorSharedService.moduleChange.next('none');
    this.moduleChangeSubscription.unsubscribe();
  }
  private loadFileFromDB() {
    this.networkCalls.getFileById(this.userId, this.documentId).subscribe(result => {
      var content = result.data.content
      this.fileHolder = result;
      this.sharedService.renameFile = result.data;
      this.amplitudeService.logAmplitude('File_Open_CTA', {
        'User ID': this.userId,
        'File Id': result.data.file_id,
        'editor': 'V1',
        'file': result.data.skip_status ? 'New' : 'Existing'
      });
      // var wordcount = document.getElementsByClassName('ql-editor')[0].textContent.trim().split(" ").length;
      // content = this.replaceEmptyParagraphsWithNewlines(content);

      let contentArr = result.data.content.split(/<\/?body[^>]*>/g)
      this.preBody = contentArr[0];
      this.postBody = content[2];
      this.editorContent = "" + contentArr[1];
      this.editorContent = this.editorContent.replace(this.SPECIAL_SPACES, "").replace(this.COMMENT_HOLDER, "").replace(this.COMMENT_DIV_REGEX, "").replace(this.COMMENT_DIV_PLACEHOLDER_REGEX, "$1");
      // if (wordcount == 1) this.quill.clipboard.dangerouslyPasteHTML(content, 'api');

      //Add the metadata required by yjs backend to save the file into db
      // this.ydoc.getMap('metadata').set('userId', this.userId);
      // this.ydoc.getMap('metadata').set('token', this.storage.get(this.metaString.getMeta())['token'])
      // for (var key in result.data) {
      //   if (key !== 'content') {
      //     this.ydoc.getMap('metadata').set(key, result.data[key]);
      //   }
      // }
      this.docTypeObject = {
        selected_document_type: result.data.document_type,
        selected_paper_type: result.data.paper_type, //Making null for now
        user_added_subject_area: null, //Making null for now
        selected_subject_area: result.data.selected_subject_area,
        subject_area_list: result.data.subject_area_list
      };
      this.subjectiveBehaviorSharedService.changeStyleGuide(result.data.style_guide, false);
      this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, false);
      this.subjectiveBehaviorSharedService.setEditorLanguage(result.data.lang_code);
      this.subjectiveBehaviorSharedService.changeInstuctionModule('child_to_parent', result.data['instruction'], result.data['module']);
      let inclusiveErrorCategories = this.setInclusiveLanguageErrorCategories();
      this.instructionModelToSend = result.data['module'] ? result.data['module'].toLowerCase() : '';
      this.instructionToSend = result.data['instruction'] ? result.data['instruction'] : '';
      this.styleGuide = result.data['style_guide'];
      //If module is not there then it will take `advanced` value initially
      this.sharedService.instructionModule.module = result.data.module ? result.data.module : "advanced";

      this.sharedService.grammarModuleConfig = {
        ...this.sharedService.grammarModuleConfig, ...{
          language: result.data.language,
          langCode: result.data.lang_code,
          style_guide: {
            value: result.data.style_guide,
            title: result.data.document_type === 4 ? this.styleGuideLegalDispay[result.data.style_guide] : this.styleGuideDispay[result.data.style_guide]
          },
          document_type: result.data.document_type,
          inclusiveErrorCategories: inclusiveErrorCategories,
          source: 'CLOUD',
          power_mode: '',
          inclusive_lang: true,
          instructionModule: {
            'module': this.instructionModelToSend,
            'instruction': this.instructionToSend
          },
          userType: this.paymentDetails['user_type']
        }
      };

      if (trinkaSDK && trinkaSDK.initializedModule && trinkaSDK.initializedModule.name === 'grammar') {
        trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, true);
      }

      // this.amplitudeService.logAmplitude('File_Open_CTA', {
      //   'User ID': this.userId,
      //   'File Id': this.documentId,
      //   'editor': 'V1',
      //   'file': result.data.skip_status ? 'New' : 'Existing'
      // });

      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      // let consistencyUrl = 'http://3.208.173.39:7500/api/v1/consistency'; //NOTE: Please use this when using on localhost

      if (result.data.skip_status) {
        let defaultLang = result.data.language;
        const dialogRef = this.dialog.open(SaveNewFileDetailsModalComponent, { data: { file_id: this.documentId, lang: defaultLang, lang_code: result.data.lang_code.split(",") } });
        dialogRef.afterClosed().subscribe(resp => {
          if (resp) {
            let selectedLanguageCode = resp.data.lang_code.split(",");
            this.subjectiveBehaviorSharedService.setEditorLanguage(selectedLanguageCode.toString());
            // this.ydoc.getMap('metadata').set('skip_status', false);
            // this.selectLanguageOptions();
            if (defaultLang != resp.data.language || resp.data.document_type != 1) {
              this.subjectiveBehaviorSharedService.changeStyleGuide(resp.data.style_guide, false);
              this.docTypeObject = {
                selected_document_type: resp.data.document_type,
                selected_paper_type: resp.data.paper_type,
                user_added_subject_area: resp.data.added_subject_area,
                selected_subject_area: resp.data.selected_subject_area,
                subject_area_list: JSON.parse(JSON.stringify(resp.data.subject_area_list))
              };

              this.docTypeObject['subject_area_list'] = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected);
              this.subjectiveBehaviorSharedService.setDocumentTypeData(this.docTypeObject, true);
            }

            this.sharedService.grammarModuleConfig = {
              ...this.sharedService.grammarModuleConfig, ...{
                language: resp.data.language,
                langCode: resp.data.lang_code,
                style_guide: {
                  value: resp.data.style_guide,
                  title: resp.data.document_type === 4 ? this.styleGuideLegalDispay[resp.data.style_guide] : this.styleGuideDispay[resp.data.style_guide]
                },
                document_type: resp.data.document_type,
                userType: this.paymentDetails['user_type']
              }
            }
            if (trinkaSDK && trinkaSDK.initializedModule &&  trinkaSDK.initializedModule.name === 'grammar') {
              trinkaSDK.initializedModule.updateTrinkaEditorConfig(this.sharedService.grammarModuleConfig, true);
            }

          }

        });
      }

      this.subjectiveBehaviorSharedService.setEditorEnglishPrefernce(result.data.language);
    });
    this.loadCreditDetails();
    this.getEditorPlagCheckFiles();
    this.getCreditDetails();
    // this.calculatenoOfScanRequired();
  }
  private applyQuillStyles() {
    const qlEdit = document.getElementsByClassName('ql-editor')[0];
    this.renderer.setStyle(qlEdit, 'padding', '12px 20%');
    this.renderer.setStyle(qlEdit, 'scrollbar-width', 'thin');
  }
  //Redo styling for the alert card holder
  private applyCustomStyles() {
    try {
      const ddcElement = document.getElementsByTagName('trinka-popup')[0].shadowRoot.getElementById('ddc');
      if (ddcElement) {
        this.renderer.setStyle(ddcElement.getElementsByClassName('dgl_m')[0], 'top', '45px');
        this.renderer.setStyle(ddcElement.getElementsByClassName('dgl_m')[0], 'right', '83px');
        this.renderer.setStyle(ddcElement.getElementsByClassName('dgl_m')[0], 'box-shadow', '0px 0px 0px 0px');
        this.renderer.removeChild(ddcElement, ddcElement.getElementsByClassName('dgl_h_lic')[0]);
        this.renderer.setStyle(ddcElement.getElementsByClassName('pluginFooterLoader')[0], 'display', 'none');
        this.renderer.setStyle(ddcElement.getElementsByClassName('dgl_h_ah')[0], 'padding', '8px 8px 10px 8px');
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  setInclusiveLanguageErrorCategories() {
    let inclusiveErrorCategories = [];
    for (const key in this.sharedService.inclusiveLanguageFilterOptions) {
      if (this.sharedService.inclusiveLanguageFilterOptions[key].checked) inclusiveErrorCategories.push(this.sharedService.inclusiveLanguageFilterOptions[key].error_category);
    }
    return inclusiveErrorCategories;
  }



  // Plag check code


  /* paraphraser end */

  getEditorPlagCheckFiles() {
    this.networkCalls.requestAllSubmitedPlgiarismReport(this.userId, this.documentId).subscribe(resp => {
      this.plagCheckFileList = resp.data;
      this.changeDetector.detectChanges();
      for (let i = 0; i < this.plagCheckFileList.length; i++) {
        if (this.plagCheckFileList[i].file_status === AutoEdit.IN_PROGRESS) {
          setTimeout(() => {
            this.getEditorPlagCheckFiles();
          }, 30000);
          return;
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  calculatenoOfScanRequired(recheckFlag: boolean = false) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS" && this.avaialbleScan >= this.calulatePageCount()) {
      this.isScanCalculated = true;

      this.networkCalls.getDocumentDetailsRequest(this.userId, this.documentId).subscribe(result => {
        if (result.status) {
          if (recheckFlag) {
            this.amplitudeService.logAmplitude('ED_run_plagcheck', {
              'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
              'word_count': result.word_count,
              'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
              'user_id': this.userId
            });
          }
          else {
            this.amplitudeService.logAmplitude('ED_run_plagcheck', {
              'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
              'word_count': result.word_count,
              'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
              'user_id': this.userId
            });
          }
          this.wordCountSections.forEach(data => {
            if (data.text == "word_count") {
              this.noOfScanRequired = Math.ceil(result.word_count / 250);
              this.plagFileWordCount = result.word_count;
              this.changeDetector.detectChanges();
            }
          })
        }
      }, error => {
        this.isScanCalculated = false;
        this.sharedService.errorHandller(error);
      });
    } else {
      this.generateReportOnCreditBased(false);
    }
  }

  process_plagiarism_check(plag_check_type) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      this.isGeneratingPlagCheckReport = true;
      let payload = new FormData();
      payload.append('plagiarism_type', plag_check_type.toString());
      payload.append('file_id', this.documentId);
      payload.append('file_name', this.fileHolder.data.file_name);
      payload.append('user_id', this.userId);
      payload.append('d_user_id', this.creditsDetails.user_id);
      if (this.avaialbleScan < this.noOfScanRequired) {
        this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
        this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
        this.router.navigate(['/plagiarism-checker/upload']);
        return;
      }


      this.amplitudeService.logAmplitude('ED_plagcheck_proceed', {
        'scans_available': this.creditsDetails.plagiarism_free_page_count_scan,
        'word_count': this.plagFileWordCount,
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId,
        'scans utilised': this.noOfScanRequired
      });

      this.networkCalls.submitDocumentForPlagiarismCheck(payload).subscribe(resp => {
        this.changeDetector.detectChanges();
        this.isGeneratingPlagCheckReport = false;
        this.noOfScanRequired = 0;
        this.isScanCalculated = false;
        this.getEditorPlagCheckFiles();
        this.getCreditDetails();
      }, error => {
        this.noOfScanRequired = 0;
        this.isScanCalculated = false;
        this.isGeneratingPlagCheckReport = false;
        this.sharedService.errorHandller(error);
      });
    } else {
      this.generateReportOnCreditBased(false);
    }
  }


  view_plag_check_report(file) {
    this.isAdvancePlagCheckReport = file.plagiarism_check_type == 2 ? true : false;
    this.amplitudeService.logAmplitude('ED_plagcheck_view_report', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
      'report_type': this.isAdvancePlagCheckReport ? 'Advance Check Report' : 'Standard Check Report'
    });

    this.dashboardService.getithenticateURL(this.userId, file.file_id, file.plagiarism_check_type, this.creditsDetails.user_id, file.submission_id).subscribe(result => {
      this.isSamplePlagCheckReport = false;
      this.plagCheckReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.data);
      this.viewerEnabled = true;
      this.changeDetector.detectChanges();
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  close_report(event) {
    this.viewerEnabled = event;
    this.isSamplePlagCheckReport = false;
    this.plagCheckReportUrl = "";
    this.amplitudeService.logAmplitude('ED_back_to_document', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId,
      'report_type': this.isAdvancePlagCheckReport ? 'Advance Check Report' : 'Standard Check Report'
    });
  }

  loadCreditDetails() {
    this.dashboardService.getStoreUserCreditDetails().subscribe(resp => {
      if (Object.keys(resp).length == 0) {
        this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
          if (response["status"]) {
            this.creditsDetails = response.data;
            this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
            this.dashboardService.storeUserCreditDetails(this.creditsDetails);
          }
          // this.totalCredits = this.creditsDetails.credits + this.creditsDetails.purchased_credits;
        }, error => {
          this.sharedService.errorHandller(error);
        })
      }
      else {
        this.creditsDetails = resp;
        this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
        this.dashboardService.storeUserCreditDetails(this.creditsDetails);
      }
    });
  }

  noTextFound() {

    // var body = this.ckEditor.instance.document.getBody();
    // var sections = this.hasAllSections(body) ? this.getSections(body) : [body];
    // sections = sections.filter(section => section.getText().trim() != "");
    // let text = sections.map(section => section.getText())
    var wordcount = document.getElementsByClassName('ql-editor')[0].textContent.trim().split(" ").length;
    this.plagFileWordCount = wordcount;
    return wordcount>0;
  }

  generate_advance_report(resp) {

    this.amplitudeService.logAmplitude('ED_advancedcheck_CTA', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId
    });
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditDetails: this.creditsDetails,
        isCreditBased: true
      };
      let dialogRef = this.dialog.open(EditorAdvancePlagCheckCreditModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.amplitudeService.logAmplitude('ED_generate_advancecheck', {
            'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
            'user_id': this.userId,
            'word_count': result.word_count,
            "credit_balance_free": this.creditsDetails.credits,
            "credit_balance_purchased": this.creditsDetails.purchased_credits
          });
          let payload = new FormData();
          payload.append('plagiarism_type', "2");
          payload.append('file_id', this.documentId);
          payload.append('file_name', this.fileHolder.data.file_name);
          payload.append('user_id', this.userId);
          payload.append('d_user_id', this.creditsDetails.user_id);
          this.networkCalls.submitDocumentForPlagiarismCheck(payload).subscribe(resp => {
            this.noOfScanRequired = 0;
            this.isScanCalculated = false;
            this.viewerEnabled = false;
            this.changeDetector.detectChanges();
            this.getEditorPlagCheckFiles();
            this.getCreditDetails();
          }, error => {
            this.noOfScanRequired = 0;
            this.isScanCalculated = false;
            this.viewerEnabled = false;
            this.changeDetector.detectChanges();
            this.sharedService.errorHandller(error);
          })
        }
      });

    } else {
      this.generateReportOnCreditBased(true);
    }
  }

  generateReportOnCreditBased(isCreditBased: boolean = false) {
    if (this.creditsDetails['user_type'] == "PREMIUM_PLUS") {
      this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
      this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
      this.amplitudeService.logAmplitude('ED_Plagcheck_with_credits_clicked', {
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId
      });
      this.router.navigate(['/plagiarism-checker/upload']);
    } else {
      this.subjectiveBehaviorSharedService.setEditorFileId(this.documentId);
      this.subjectiveBehaviorSharedService.setEditorFileName(this.fileHolder.data.file_name);
      this.amplitudeService.logAmplitude('ED_Plagcheck_with_credits_clicked', {
        'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
        'user_id': this.userId
      });
      this.router.navigate(['/plagiarism-checker/upload']);
    }
  }

  viewSampleReport(plag_type = 3) {
    let submission_id = this.sampleCard["plagiarism_check_type"] == plag_type ? this.sampleCard['submission_id'] : this.sampleCard['turnitine_submission_id'];
    this.isAdvancePlagCheckReport = true;
    this.amplitudeService.logAmplitude('ED_plagcheck_viewsample', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails.user_type),
      'user_id': this.userId
    });
    this.dashboardService.getithenticateURL(this.userId, this.sample_file_id, plag_type, this.creditsDetails.user_id, submission_id).subscribe(result => {
      this.isSamplePlagCheckReport = true;
      this.plagCheckReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.data);
      this.viewerEnabled = true;
      this.changeDetector.detectChanges();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }


  getCreditDetails() {
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response.data;
        this.avaialbleScan = this.creditsDetails['plagiarism_free_page_count_scan'];
        this.dashboardService.storeUserCreditDetails(this.creditsDetails);
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  getFileWordCountDetails() {
    this.networkCalls.getDocumentDetailsRequest(this.userId, this.documentId).subscribe(result => {
      if (result.status) {
        this.wordCountSections.forEach(data => {
          if (data.text == "word_count") {
            this.plagFileWordCount = result.word_count;
            this.changeDetector.detectChanges();
          }
        })
      }
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  calulatePageCount() {
    return Math.ceil(this.plagFileWordCount / 250)
  }


  upgradeToPremium() {
    this.amplitudeService.logAmplitude('ED_Plag_check_upgrdae_clicked', {
      'subscription_type': this.sharedService.uppercaseFirstLetter(this.creditsDetails['user_type']),
      'user_id': this.userId,
      "credit_balance_free": this.creditsDetails['credits'],
      "credit_balance_purchased": this.creditsDetails['purchased_credits']
    });
    let redirct_url = environment.payment_fe;

    let data = {
      "user_id": this.userId,
      "plan_name": this.sharedService.uppercaseFirstLetter(this.creditsDetails['user_type']).toLowerCase(),
      "from": "Trinka"
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url + '/user/' + this.userData, "_blank")
  }

  checkReportHistory() {
    if (this.plagCheckFileList.length == 2 && this.plagCheckFileList[0].file_status == 'IN_PROGRESS') {
      return false;
    } else if (this.plagCheckFileList.length == 2 && this.plagCheckFileList[0].file_status == 'SUCCESS') {
      return true;
    } else {
      return true;
    }
  }

  checkLastGeneratedReport() {
    if (this.plagCheckFileList.length == 1 && this.plagCheckFileList[0].file_status == 'IN_PROGRESS') {
      return false;
    } else if (this.plagCheckFileList.length == 1 && this.plagCheckFileList[0].file_status == 'SUCCESS') {
      return true;
    } else {
      return true;
    }
  }

  showAllSelectedCode() {
    let languageToBeDisplayed = ''
    if (this.selectedLanguageCode.length === 1) {
      let tempArr = this.languageCodeOptions.filter((x) => x.code === this.selectedLanguageCode[0]);
      languageToBeDisplayed = tempArr && tempArr.length > 0 ? tempArr[0].value.slice(0, -4) : '';
    }
    else languageToBeDisplayed = this.selectedLanguageCode.toString().toLocaleUpperCase().replace(/,/g, ', ');
    return languageToBeDisplayed;
  }

  selectLanguageOptions() {
    this.languageCodeOptions.forEach((x) => {
      x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
    });
    this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
    this.subjectiveBehaviorSharedService.setEditorLanguage(this.selectedLanguageCode.toString());
  }

  replaceEmptyParagraphsWithNewlines(html) {
    // Create a DOM parser
    const parser = new DOMParser();
    // Parse the HTML string
    const doc = parser.parseFromString(html, 'text/html');

    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');

    //Select all <li> elements
    const listItem = doc.querySelectorAll('li span');

    // Loop through the <p> elements and replace those that are empty or contain only &nbsp; with newlines
    paragraphs.forEach((p) => {
      const content = p.innerHTML.trim();
      if (content === '&nbsp;' || content === '') {
        const newline = document.createTextNode('\n');
        p.parentNode.replaceChild(newline, p);
      }
    });

    // Loop through the <li> elements and replace those that are empty or contain only &nbsp; with newlines
    listItem.forEach((item) => {
      const content = item.innerHTML.trim();
      if (content === '&nbsp;' || content === '') {
        const newline = document.createTextNode('');
        item.parentNode.replaceChild(newline, item);
      }
    })

    // Serialize the DOM back to a string
    return doc.body.innerHTML;
  }

  public _saveFile(callback?, forceUpdate: boolean = false) {
    console.log("saving ....")
    return new Promise((resolve, reject) => {

      let content = (document.querySelector('div.ck-editor__editable') as any).ckeditorInstance.getData();
      // content = content.replace(this.ALERT_REGEX, "")
      //   .replace(this.SENTENCE_REGEX, "")
      //   .replace(this.CC_ALERT_REGEX, "")
      //   .replace(this.SPECIAL_SPACES, "")
      //   .replace("</div><p>&nbsp;</p><p>&nbsp;</p><div><div style=\"-aw-headerfooter-type:header-primary\">", "</div><br style=\"clear:both; mso-break-type:section-break\"/><div><div style=\"-aw-headerfooter-type:header-primary\">")
      //   .replace("</div><p>&nbsp;</p><div><div style=\"-aw-headerfooter-type:header-primary\">", "</div><br style=\"clear:both; mso-break-type:section-break\"/><div><div style=\"-aw-headerfooter-type:header-primary\">");

      // content = this.preBody + "<body>" + this.COMMENT_HOLDER + content + this.commentDiv + "</body>" + this.postBody;
      content = "<html><head><meta charset=\"UTF-8\"></head><body>" + content + "</body></html>";
      this.subjectiveBehaviorSharedService.getEditorLanguage().subscribe(language => {
        this.selectedLanguageCode = language.split(',');
        this.languageCodeOptions.forEach((x) => {
          x.isSelected = this.selectedLanguageCode.indexOf(x.code) > -1;
        });
        this.languageString = this.languageCodeOptions.filter((x) => x.isSelected).map(obj => obj.value);
      })
      // Document type payload
      this.fileHolder.data.document_type = this.docTypeObject['selected_document_type'];
      this.fileHolder.data.paper_type = this.docTypeObject['selected_paper_type'];
      this.fileHolder.data.selected_subject_area = this.docTypeObject['selected_subject_area'];
      // this.fileHolder.data.added_subject_area = this.docTypeObject['user_added_subject_area'];
      this.fileHolder.data.subject_area_list = this.docTypeObject['subject_area_list'].sort((a, b) => b.user_added_flag - a.user_added_flag).sort((a, b) => b.isSelected - a.isSelected);
      // this.fileHolder.data['powermode_active'] = this.powerModeSelection;
      this.fileHolder.data['lang_code'] = this.selectedLanguageCode.toString();
      this.fileHolder.data['module'] = this.instructionModelToSend;
      this.fileHolder.data['instruction'] = this.instructionToSend;

      // if (forceUpdate || this.fileHolder.data.content !== content) {
        this.savingSection = true;
        this.fileHolder.data.content = content;
        this.saving = true;
        if (this.interval != null) {
          clearInterval(this.interval);
        }
        this.fileHolder.data['style_guide'] = this.styleGuide ? this.styleGuide : "NONE"
        this.networkCalls.putUpdateFile(this.userId, this.fileHolder.data).subscribe(result => {
          this.saving = false;
          this.savingSection = true;
          this.lastSavedTime = new Date();
          if (callback) { callback(); }

          this.interval = setTimeout(() => {
            this.savingSection = false;
          }, 3000);

          resolve(result);
        }, error => {
          this.saving = false;
          this.sharedService.errorHandlerForFIleSave(error);

          reject(error);
        });
      // }
      // else {
      //   resolve(true);
      // }

    });
  }

  debounceSaveFile(timeout = 300) {
    if (this.saveFileTimeout) return;
    this.saveFileTimeout = setTimeout(() => {
      this._saveFile();
      clearTimeout(this.saveFileTimeout);
      this.saveFileTimeout = undefined;
    }, timeout);
  }
}
