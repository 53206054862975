import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss']
})
export class CkEditorComponent implements OnInit {

 // @Input('value') editorValue: any;

  config = {
    startupFocus: true,
    allowedContent: true,
    disableNativeSpellChecker: true,
    toolbar: [
      { name: 'styles', items: ['Format', 'Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', '-', 'NumberedList', 'BulletedList'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'insert', items: ['Table', 'SpecialChar', 'PageBreak'] },
    ],
    contentsCss: ["div[id^='_cmnt'] {display: none;},body{overflow-y: hidden}", "table{width: 100%!important;max-width:100%}", "pre{white-space: pre-wrap;}", "gcalert{border-bottom-style: solid;solid;}", ".grammar{border-bottom-color: #763293;}", ".spelling{border-bottom-color: #fd9a00;}", ".suggestion{border-bottom-color: #ff5c5d;}", ".grammar_active{background-color: rgba(118,50,147,0.4);}",
      ".spelling_active{background-color: rgba(253,154,0,0.4);}", ".suggestion_active{background-color: rgba(255,92,93,0.4);}", ".sticky-top{top: 0;bottom: 0;z-index: 1;position: fixed;height: 30px;background: white;width: 100%;}", ".tempTagRemove{border-bottom-style: none !important;background-color: white !important;}"],
    removePlugins: 'iframe,contextmenu,liststyle,tabletools,tableselection,magicline,link',
    stylesSet: "/src/styles.css",
    extraPlugins: 'autogrow,confighelper',
    language: 'en',
    autoGrow_onStartup: true,
    autoGrow_minHeight: window.innerHeight - 100,
    autoGrow_maxHeight: 150000,
  }

  constructor() {
   }

  ngOnInit() {
  }

}
