import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatDialog, MatRadioChange, MatDialogConfig } from '@angular/material';
import { SharedService } from 'src/app/_services/shared.service';
import { DashboardService } from 'src/app/_services/networkcalls/dashboard.service';
import { VerifiedUserService } from 'src/app/_services/networkcalls/verified-user.service';
import { AmplitudeService } from 'src/app/_services/amplitude.service';
import { PaymentDetails } from 'src/app/_interfaces/dashboard/payment-details';
import { AutoEdit } from 'src/app/_enums/auto-edit.enum';
import { environment } from 'src/environments/environment';
import { CreditDetailsComponent } from '../credit-details/credit-details.component';
import { PlagiarismReportGenerateComponent } from './plagiarism-report-generate/plagiarism-report-generate.component';
import { PlagiarismReportComparisonComponent } from './plagiarism-report-comparison/plagiarism-report-comparison.component';
import { SubjectiveBehaviorSharedService } from 'src/app/_services/subjective-behavior-shared.service';
import { PlagiarismHelpToChooseReportModalComponent } from './plagiarism-help-to-choose-report-modal/plagiarism-help-to-choose-report-modal.component';
import { PlagiarismScoreOnlySampleReportModalComponent } from './plagiarism-score-only-sample-report-modal/plagiarism-score-only-sample-report-modal.component';
import { PlagiarismTrinkaSampleReportModalComponent } from './plagiarism-trinka-sample-report-modal/plagiarism-trinka-sample-report-modal.component';
import { PlagiasrimIthenticateSampleReportModalComponent } from './plagiasrim-ithenticate-sample-report-modal/plagiasrim-ithenticate-sample-report-modal.component';
import { ShowMyDriveFilesModalComponent } from '../show-my-drive-files-modal/show-my-drive-files-modal.component';
import { DropboxChooseOptions,Dropbox } from 'src/app/_interfaces/dashboard/dropbox-choose-options';
import { GoogleDrivePickerService } from 'src/app/_services/google-drive-picker.service';

declare function wisepop():any;
declare const OneDrive: any;
declare var Dropbox: Dropbox;
declare function setUserPropartiesWisePop(setUserPropartiesWisePop): any;
@Component({
  selector: 'app-plagiarism-check',
  templateUrl: './plagiarism-check.component.html',
  styleUrls: ['./plagiarism-check.component.scss']
})
export class PlagiarismCheckComponent implements OnInit {
  win = window['dataLayer'] || {}
  element: HTMLElement;
  userId: any;
  userData: any;
  paymentDetails : PaymentDetails;
  creditsDetails: any;
  fileName: string;
  selectedPlagCheckName: string;
  plagiarismFilesList: any = [];
  plagiarism_check_list : any = [];
  isFileSelected = null;
  files_submitted = [];
  selectedFiles: FileList;
  plagiarismFile = null;
  selected_type : number;
  freeStatus: boolean = false;
  filterModel = "";
  user_decoded_id: any;
  dataSource: Object;
  report_data:any = [];
  free_score_reset_date:any;
  remainig_free_score: number;
  total_free_score: number;
  credit_used: number;
  cloudDriveFileDetails = {"fileId":"", "fileName": "", "fileUrl":"", "source": "", "oauthToken": "", "userId" :""};
  uploadSource =[
    {
     "GOOGLE_DRIVE" : false,
     "TRINKA_DRIVE" : false,
     "ONE_DRIVE"    : false,
     "DROP_BOX"     : false,
     "LOCAL_SYSTEM" : false
     }
   ];
  @ViewChild('plagiarismFile', { static: true }) plagiarismFileControl: any;

  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private networkCalls: DashboardService,
    private verifiedService: VerifiedUserService,
    private amplitudeService: AmplitudeService,
    private subjectiveBehaviorSharedService: SubjectiveBehaviorSharedService,
    private googleDrivePickerService: GoogleDrivePickerService,
    private appRef: ApplicationRef
  ) { 
    this.plagiarism_check_list=[
      {'id' : 1 ,'type' : 'Score Only' , 'checked': false, credit: 0},
      {'id' : 2 ,'type' : 'iThenticate Plagiarism' , 'checked': false, credit: 18},
      {'id' : 3 ,'type' : 'Trinka Plagiarism' , 'checked': false, credit: 12},
    ]
  }

  ngOnInit() {
    wisepop();
    const localMeta = this.sharedService.getLocalStorageMeta();
    this.userId = btoa(localMeta.sub)
    let data = {
      "user_id" : this.userId
    };
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    this.getCreditsDetails(true);
    this.networkCalls.setDashboardCloudFileDetails(null);
    this.networkCalls.setDashboardLocalFileDetails(null);
    this.verifiedService.getSelectedProfileData();
    this.getUserPaymentSubscription();
    this.getPlagiarismFileList();
    this.subjectiveBehaviorSharedService.getEditorFileId().subscribe(file_id => {
        this.uploadSource["TRINKA_DRIVE"] = true;
        this.cloudDriveFileDetails.fileId = file_id;
    });
    this.subjectiveBehaviorSharedService.getEditorFileName().subscribe(file_name => {
      this.cloudDriveFileDetails.fileName = file_name;
      this.fileName = file_name;
      if(file_name){
      if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
        this.fileName +=".docx"
       }
      }
      this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
      this.cloudDriveFileDetails.userId = this.userId;
    });
  }

  arrayChunk(list: any[], chunkSize: number) {
    var R = [];
    for (var i = 0; i < list.length; i += chunkSize)
      R.push(list.slice(i, i + chunkSize));
    return R;
  }


  getCreditsDetails(isOnInit:boolean= false){
    this.networkCalls.getCreditsDetailsByUser(this.userId).subscribe(response => {
      if (response["status"]) {
        this.creditsDetails = response["data"];
        this.networkCalls.storeUserCreditDetails(this.creditsDetails);
        this.user_decoded_id = this.creditsDetails["user_id"];
        this.freeStatus = this.creditsDetails["plagiarism_free_score"] > 0 ? true : false;
        this.free_score_reset_date = this.creditsDetails["next_renewal_date"];
        this.total_free_score = this.creditsDetails["user_type"]=="BASIC" ? 1: 2;
        this.remainig_free_score = this.creditsDetails["plagiarism_free_score"] ? this.creditsDetails["plagiarism_free_score"] : 0;
        if(isOnInit){
          this.amplitudeService.logAmplitude('Plag_toolpage_view',{
            'userID':this.userId,
            'free_credit_balance': this.creditsDetails.credits,
            'premium_credit_balance': this.creditsDetails.purchased_credits
            });
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  getUserPaymentSubscription(){
    this.networkCalls.getStoreUserPlanDetails().subscribe(resp=> {
      if(Object.keys(resp).length == 0){
          this.networkCalls.getUserPaymentSubscription(this.userId).subscribe(response => {
            this.paymentDetails = response['data'];
            this.networkCalls.storeUserPlanDetails(this.paymentDetails);
            let wisepop_user_proparties = {
              userSubscriptionType: this.paymentDetails['is_premium']?'Premium':'Basic',
              credit_balance: this.creditsDetails.credits + this.creditsDetails.purchased_credits
            }
            setUserPropartiesWisePop(wisepop_user_proparties);
          });
        }
        else{
          this.paymentDetails = resp;
        }
    });
  }

  validFile = false;
  validateFileType(file) {
    const fileType = file.name.split('.').pop().toLowerCase().toString();

    if (fileType === 'doc' || fileType === 'docx') {
      this.validFile = true;
    } else {
      this.validFile = false;
    }
  }


  onFileChange(event) {
    this.files_submitted = []
    this.selectedFiles = event;
    if (this.selectedFiles.length > 1) {
      this.fileName = this.selectedFiles.length + " files selected";
    } else {
      this.fileName = this.selectedFiles[0].name;
    }

    try {
      for (var i = 0; i < this.selectedFiles.length; i++) {
        var file = event[i];
        this.files_submitted.push(file)
        this.validateFileType(file);
        if (!this.validFile) {

          this.toastr.error('Please upload file with doc, docx format.',"Error");
          this.plagiarismFile = null;
          this.uploadSource["LOCAL_SYSTEM"] = false;
          break
        } else {
          this.plagiarismFile = file;
          this.isFileSelected = true;
          this.uploadSource["LOCAL_SYSTEM"] = true;
          this.amplitudeService.logAmplitude('Plag_upload_LocalDrive',{
            'free_credit_balance': this.creditsDetails.credits,
            'premium_credit_balance': this.creditsDetails.purchased_credits
          });
        }
      }
    } catch (error) {
      this.isFileSelected = false;
      this.uploadSource["LOCAL_SYSTEM"] = false;
    }
  }


  plagiarismChange(val){
    if(this.selected_type){
      this.plagiarism_check_list[this.selected_type-1].checked = false;
    }
      let selected_id = val;
      for(let index =0 ; index < this.plagiarism_check_list.length; index++){
        if(selected_id == this.plagiarism_check_list[index].id){
          this.plagiarism_check_list[index].checked = true;
          this.selected_type = this.plagiarism_check_list[index].id;
          this.selectedPlagCheckName = this.plagiarism_check_list[index].type;
          this.credit_used = this.plagiarism_check_list[index].credit;
        }
      }
  }

  uploadPlagiarismFile() {

    if(this.fileName == null || this.fileName ==""){
      this.toastr.error('Please upload file with doc, docx format.',"Error");
      return;
    }

    if(!(this.fileName.endsWith("doc") || this.fileName.endsWith("docx"))){
      this.toastr.error('Please upload file with doc, docx format.',"Error");
      return;
    }

    if(this.selected_type== null){
      this.toastr.error("Please select plagiarism check");
      return;
    }

    if(this.selected_type == 1 && this.creditsDetails.user_type != "PREMIUM" && this.creditsDetails["plagiarism_free_score"] <= 0){
      this.toastr.error("Fair Use of limit exceed");
      return;
    }

    if(this.selected_type == 1 && this.creditsDetails.user_type == "PREMIUM" && this.creditsDetails["plagiarism_free_score"] <= 0){
      this.toastr.error("Fair Use of limit exceed");
      return;
    }

    this.amplitudeService.logAmplitude('Plag_submit_CTA',{
      'Report Type': this.selectedPlagCheckName,
      'Credit_used':  this.credit_used,
      'free_credit_balance': this.creditsDetails.credits,
      'premium_credit_balance': this.creditsDetails.purchased_credits
    });

    if(this.uploadSource["LOCAL_SYSTEM"]){
      this.uploadLocalFileForPlagCheck();
    }
    else{
      this.uploadCloudFileForPlagCheck();
    }
  }

  uploadLocalFileForPlagCheck(initialIndex = 0, flag = false){
    var file_length = 0
    if (this.plagiarismFile || flag) {
      try {
        var chunkArray = this.arrayChunk(this.files_submitted, 5);
        let index = 0;
        for (index = 0; index < chunkArray[initialIndex].length; index++) {
          const element = chunkArray[initialIndex][index];
          this.resetCloudFileDetails();
          this.cloudDriveFileDetails.source = "LOCAL_SYSTEM";
          this.cloudDriveFileDetails.userId = this.userId;
          var fileObject = new FormData();
          file_length = initialIndex;
          fileObject.append("file", element);
          fileObject.append("plagiarism_type",this.selected_type.toString());
          fileObject.append("user_id", this.userId);
          // console.log('2: plag-check only');
          fileObject.append("d_user_id",this.user_decoded_id);
          fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails))
          if (!flag) {
            this.spinner.show();
          }
          this.networkCalls.uploadPlagiarismFile(fileObject).subscribe(res => {
              if (initialIndex == chunkArray.length - 1) {
                this.toastr.success("Plagiarism check has begun. The report will appear in the Generated Reports section.");
                this.spinner.hide();
              }
              if (res['status']) {
                this.plagiarismFileControl.nativeElement.value = null;
                this.plagiarismFile = null;
                this.fileName = null;
                this.plagiarism_check_list[this.selected_type-1].checked = false;
                this.getPlagiarismFileList();
              }
          }, error => {
            this.plagiarismFileControl.nativeElement.value = null;
            this.plagiarismFile = null;
            this.fileName = null;
            this.plagiarism_check_list[this.selected_type-1].checked = false;
            file_length = initialIndex;
            this.sharedService.errorHandller(error);
            this.getPlagiarismFileList();
          })
        }
        if (index != chunkArray[initialIndex].length - 1) {
          setTimeout(() => {
            this.uploadLocalFileForPlagCheck(initialIndex + 1, true);
            this.getPlagiarismFileList();
          }, 2000);
        }
      }
      catch (error) {
        this.isFileSelected = false;
      }
    } else {
      this.toastr.error('Please upload file with doc, docx format.',"Error");
    }
  }

  uploadCloudFileForPlagCheck(){
      try {
          this.cloudDriveFileDetails.userId = this.userId;
          var fileObject = new FormData();
          fileObject.append("plagiarism_type",this.selected_type.toString());
          fileObject.append("user_id",this.userId);
          fileObject.append("d_user_id",this.user_decoded_id);
          fileObject.append('cloudFileDetails', JSON.stringify(this.cloudDriveFileDetails))

          this.spinner.show();
          this.networkCalls.uploadPlagiarismFile(fileObject).subscribe(res => {
            this.spinner.hide();
              // this.amplitudeService.logAmplitude('tool_check_submit',{
              //   'selected_check': this.plagiarism_check_list[this.selected_type-1].type,
              //   'credits_required': this.plagiarism_check_list[this.selected_type-1].credit
              //   });
              if (res['status']) {
                this.toastr.success("Plagiarism check has begun.It will appear in the Generated Reports section.");
                this.plagiarismFileControl.nativeElement.value = null;
                this.plagiarismFile = null;
                this.fileName = null;
                this.plagiarism_check_list[this.selected_type-1].checked = false;
                this.getPlagiarismFileList();
              }
          }, error => {
            this.spinner.hide();
            this.plagiarismFileControl.nativeElement.value = null;
            this.plagiarismFile = null;
            this.fileName = null;
            this.plagiarism_check_list[this.selected_type-1].checked = false;
            this.sharedService.errorHandller(error);
            this.getPlagiarismFileList();
          });
      }
      catch (error) {
        this.spinner.hide();
        this.isFileSelected = false;
      }
  }

  downloadFile(file, plag_type){
     let report_type = plag_type == 2 ? 'advance check' : 'standard check'
      this.amplitudeService.logAmplitude('Plag_view_report',{
      'Report Type': report_type
      });
    this.sharedService.setPlagarismData(file.file_id,this.userId, plag_type);
    this.sharedService.downloadPlagiarismFile(this.user_decoded_id); 
  }


  getPlagiarismFileList(flag: boolean = true) {
    // if(!flag){
    //   console.log("flag "+flag+" show spinner");
    //   this.spinner.show();
    // }
    this.networkCalls.getPlagiarismFileList(this.userId).subscribe(res => {
      if (res["status"]) {
        this.plagiarismFilesList = res.data;
        this.spinner.hide();
        for (let i = 0; i < this.plagiarismFilesList.length; i++) {
          if (this.plagiarismFilesList[i].file_status === AutoEdit.IN_PROGRESS) {
            setTimeout(() => {
              this.getPlagiarismFileList(false);
            }, 60000);
            return ;
          }
        }
      }
    }, error => {
      this.sharedService.errorHandller(error);
    })
    this.getCreditsDetails()
  }

  redirectIthenticate(file){

      this.amplitudeService.logAmplitude('Plag_report_view',{
      'fileID': file.file_id,
      'selected_check': this.plagiarism_check_list[file.plagiarism_check_type-1].type,
      });
    let submission_id = file.plagiarism_check_type == 2 ? file.submission_id : file.turnitine_submission_id;
    this.networkCalls.getithenticateURL(this.userId,file.file_id,file.submission_id,this.user_decoded_id, submission_id).subscribe(result => {
      window.open(result.data)
    }, error => {
      this.sharedService.errorHandller(error);
    })
  }

  deleteFile(action, file) {
    this.spinner.show();
    this.networkCalls.deleteFileByIdPlagiarism(this.userId, file.file_id, this.user_decoded_id).subscribe(res => {

        this.amplitudeService.logAmplitude('plag_file_delete',{
        'fileID': file.file_id,
        'selected_check': this.plagiarism_check_list[file.plagiarism_check_type-1].type       
        });

         // this.spinner.hide();
         this.getPlagiarismFileList();
         let message = file.file_name + " is deleted"
         let snakRef = this._snackBar.open(message, action, {
           duration: 10000,
           horizontalPosition: "right",
          });
          snakRef.onAction().subscribe(() => this.restoreFile(file));
          
        }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  restoreFile(file) {
    this.spinner.show();
    this.networkCalls.restoreFileByIdPlagiarism(this.userId, file.file_id, this.user_decoded_id).subscribe(res => {
      this.spinner.hide();
      this.getPlagiarismFileList();
    }, error => {
      this.sharedService.errorHandller(error);
    });
  }

  addCredits() {
    this.amplitudeService.logAmplitude('Add_credits_CTA',{
      'Subscription Type': this.creditsDetails.user_type,
      'credit_balance_free': this.creditsDetails.credits,
      'credit_balance_purchased': this.creditsDetails.purchased_credits
       });
    let redirct_url  = environment.payment_fe;
    let data = this.sharedService.generateAddCreditsPayload(this.userId, this.creditsDetails.user_type,window.location.href);
    
    this.userData = btoa(encodeURIComponent(JSON.stringify(data)));
    window.open(redirct_url+'/user/credit/'+this.userData,"_self");
  }

  reUploadFile(file){
    if(file.file_status == 'ON_HOLD'){
      this.networkCalls.reUploadOnholdPlagiarismFile(this.userId,file.file_id,file.plagiarism_check_type, this.user_decoded_id).subscribe(res => {
        this.getPlagiarismFileList();
        this.getCreditsDetails();
      }, error => {
        this.sharedService.errorHandller(error);
      })
    }
  }

  credits(){
    if(this.creditsDetails != null){
      this.amplitudeService.logAmplitude('Plag_page_credit_view',{
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
         });
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        creditData : this.creditsDetails,
      };
      this.dialog.open(CreditDetailsComponent, dialogConfig)
    }
  }

  parseJson(str: any): any {
    this.report_data = JSON.parse(str);
  }

  generatePlagiarismReport(file, plagiarism_type){
    
    if(plagiarism_type==2){
      this.amplitudeService.logAmplitude('Plag_Get-ithenticate_Report',{
        'FileID': file.file_id,
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
      });
    }else{
      this.amplitudeService.logAmplitude('Plag_Get-Trinka_report',{
        'FileID': file.file_id,
        'credit_balance_free': this.creditsDetails.credits,
        'credit_balance_purchased': this.creditsDetails.purchased_credits
      });
    }

    this.dialog.open(PlagiarismReportGenerateComponent, { width: "500px", data: {file: file, requested_plagiarism_type: plagiarism_type, user_decoded_id: this.user_decoded_id} }).afterClosed().subscribe( response => {
      this.spinner.show();
      if(response != null){
            if(plagiarism_type==2){
              this.amplitudeService.logAmplitude('Plag_Confirm-ithenticate_Report',{
                'FileID': file.file_id,
                'credit_balance_free': this.creditsDetails.credits,
                'credit_balance_purchased': this.creditsDetails.purchased_credits
              });
            }
            else{
              this.amplitudeService.logAmplitude('Plag_Cancel-Trinka_Report',{
                'FileID': file.file_id,
                'credit_balance_free': this.creditsDetails.credits,
                'credit_balance_purchased': this.creditsDetails.purchased_credits
              });
            }
          
      }else{

        if(plagiarism_type==2){
          this.amplitudeService.logAmplitude('Plag_Cancel-ithenticate_Report',{
            'FileID': file.file_id,
            'credit_balance_free': this.creditsDetails.credits,
            'credit_balance_purchased': this.creditsDetails.purchased_credits
          });
        }
        else{
          this.amplitudeService.logAmplitude('Plag_Cancel-Trinka_Report',{
            'FileID': file.file_id,
            'credit_balance_free': this.creditsDetails.credits,
            'credit_balance_purchased': this.creditsDetails.purchased_credits
          });
        }
      }
      this.getPlagiarismFileList(false);
    });
  }

  openCompareModel(){
      this.dialog.open(PlagiarismReportComparisonComponent);
  }

  resetCloudFileDetails(){
    let that= this;
    this.fileName = null;
    Object.keys(this.uploadSource).forEach(function(key){
      that.uploadSource[key] = false;
    });

    Object.keys(this.cloudDriveFileDetails).forEach(function(key){
      that.cloudDriveFileDetails[key] = "";
    });
    this.plagiarismFileControl.nativeElement.value = null;
    this.appRef.tick();
  }

  needHelpToChooseReport(){
    let dialogRef = this.dialog.open(PlagiarismHelpToChooseReportModalComponent,{data :{credits : (this.creditsDetails.credits + this.creditsDetails.purchased_credits), free_score:this.remainig_free_score, selectedPlagCheckType: this.selected_type}});
    dialogRef.afterClosed().subscribe(result => {
      this.plagiarismChange(result);
      this.amplitudeService.logAmplitude('Plag_needHelp_CTA',{ 
        'Subscription Type':  this.selectedPlagCheckName
      });
    });
  }

  scoreOnlySampleReport(){
    this.amplitudeService.logAmplitude('Score_viewsample',{
      'Subscription Type': this.creditsDetails.user_type,
      'free_credit_balance': this.creditsDetails.credits,
      'premium_credit_balance': this.creditsDetails.purchased_credits
    });
    this.dialog.open(PlagiarismScoreOnlySampleReportModalComponent);
  }

  trinkaSampleReport(){
    this.amplitudeService.logAmplitude('Trinka_viewsample',{
      'Subscription Type': this.creditsDetails.user_type,
      'free_credit_balance': this.creditsDetails.credits,
      'premium_credit_balance': this.creditsDetails.purchased_credits
    });
    this.dialog.open(PlagiarismTrinkaSampleReportModalComponent);
  }

  ithenticateSampleReport(){
    this.amplitudeService.logAmplitude('ithenticate_viewsample',{
      'Subscription Type': this.creditsDetails.user_type,
      'free_credit_balance': this.creditsDetails.credits,
      'premium_credit_balance': this.creditsDetails.purchased_credits
    });
    this.dialog.open(PlagiasrimIthenticateSampleReportModalComponent);
  }

  launchDropBoxPicker(){

    this.resetCloudFileDetails();
     this.uploadSource["DROP_BOX"] = true;
     this.cloudDriveFileDetails.fileId = "";
     let that =this;
     var options: DropboxChooseOptions = {
       success: function (files) {
            for (const file of files) {
                   that.fileName = file.name;
                   that.cloudDriveFileDetails.fileName = file.name;
                   that.cloudDriveFileDetails.fileUrl = file.link;
                   that.cloudDriveFileDetails.source = "DROP_BOX";
                   that.amplitudeService.logAmplitude('Plag_upload_DropBox',{
                    'free_credit_balance': that.creditsDetails.credits,
                    'premium_credit_balance': that.creditsDetails.purchased_credits
                  });
                   that.appRef.tick();
               }
       },
       cancel: function () {
         console.log("cancel  selected..")
       },
       linkType: "direct",
       multiselect: false,
       extensions: ['.doc', '.docx'],
   };
 
   Dropbox.choose(options);
  }

  launchGoogleDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["GOOGLE_DRIVE"] = true;
    this.cloudDriveFileDetails.fileId = "";
    this.googleDrivePickerService.open((data) => {
      if (data.action === 'picked') {
        let file_details = data.docs[0];
        this.fileName = file_details.name;
        if(file_details.name.endsWith(".txt")){
          this.toastr.error('Please upload file with doc, docx format.',"Error");
          return;
        }
        if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
          this.fileName +=".docx"
        }
        this.cloudDriveFileDetails.fileId = file_details.id;
        this.cloudDriveFileDetails.fileName = this.fileName;
        this.cloudDriveFileDetails.source = "GOOGLE_DRIVE";
        this.cloudDriveFileDetails.oauthToken = this.googleDrivePickerService.oauthAccessToken;
        this.amplitudeService.logAmplitude('Plag_upload_Gdrive',{
          'free_credit_balance': this.creditsDetails.credits,
          'premium_credit_balance': this.creditsDetails.purchased_credits
        });
        this.appRef.tick();
      }
    });
  }

  launchOneDrivePicker(){

    this.resetCloudFileDetails();
     this.uploadSource["ONE_DRIVE"] = true;
     this.cloudDriveFileDetails.fileId = "";
     var that =this;
     var odOptions = {
      clientId: environment.one_drive_client_id,
      action: "download",
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        queryParameters: "select=id,name,size,file,folder,@microsoft.graph.downloadUrl",
        filter: [".docx",".doc"],
        redirectUri: environment.cloud_redirect_url+"dashboard/auto-edit",
        },
       success: function(files) { 
         for (const file of files.value) {
             that.fileName = file.name;
             that.cloudDriveFileDetails.fileId = file.id;
             that.cloudDriveFileDetails.fileName = file.name;
             that.cloudDriveFileDetails.fileUrl = file['@microsoft.graph.downloadUrl'];
             that.cloudDriveFileDetails.source = "ONE_DRIVE";
             that.amplitudeService.logAmplitude('Plag_upload_Onedrive',{
              'free_credit_balance': that.creditsDetails.credits,
              'premium_credit_balance': that.creditsDetails.purchased_credits
            });
            that.appRef.tick();
         }
        },
       cancel: function() { 
         console.log("cancel   ");
        },
       error: function(e) { 
         console.log("Error occured at one drive  "+e);
        }
     }
     OneDrive.open(odOptions);
  }
  launchTrinkaDrivePicker(){
    this.resetCloudFileDetails();
    this.uploadSource["TRINKA_DRIVE"] = true;
    const dialogRef = this.dialog.open(ShowMyDriveFilesModalComponent,{ autoFocus: false 
    });
    dialogRef.afterClosed().subscribe(result => {
     this.fileName = result.file_name;
     if(!(this.fileName.endsWith(".doc") || this.fileName.endsWith(".docx"))){
      this.fileName +=".docx"
     }
     this.cloudDriveFileDetails.fileId = result.file_id;
     this.cloudDriveFileDetails.fileName = this.fileName;
     this.cloudDriveFileDetails.source = "TRINKA_DRIVE";
     this.cloudDriveFileDetails.userId = this.userId;
     this.amplitudeService.logAmplitude('Plag_upload_Trinka',{
      'FileID': result.file_id,
      'free_credit_balance': this.creditsDetails.credits,
      'premium_credit_balance': this.creditsDetails.purchased_credits
    });
    });
  }
}
