<mat-list class="row headerBoxShadow ManageProfilemobileHeader">
    <div class="col-md-12 top_new_header">
        <div class="page_top_header">
            <h1>Usage Analytics</h1>
        </div>
    </div>
</mat-list>

<div class="ad_outWraaperSection" onload="applyFilter($event)">
    <p class="dataAndTimeSection">Data available till {{yesterday_date | date: 'd/MMM/y'}} 11:59 PM UTC <span class="learn_more" (click)="openLearnMore()">Learn More</span></p>
    <div class="ad_FillterSection" [formGroup]="filtersSelection">

        <div class="fillterByCalender fillterFeilds"  [ngClass]="showDayWiseErrorMsg || showNintiyDayErrorMsg ? 'groupByErrorSection' : '' ">
            <label for="">Select Date</label>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.6665 4.79199C6.32484 4.79199 6.0415 4.50866 6.0415 4.16699V1.66699C6.0415 1.32533 6.32484 1.04199 6.6665 1.04199C7.00817 1.04199 7.2915 1.32533 7.2915 1.66699V4.16699C7.2915 4.50866 7.00817 4.79199 6.6665 4.79199Z"
                    fill="#292D32" />
                <path
                    d="M13.3335 4.79199C12.9918 4.79199 12.7085 4.50866 12.7085 4.16699V1.66699C12.7085 1.32533 12.9918 1.04199 13.3335 1.04199C13.6752 1.04199 13.9585 1.32533 13.9585 1.66699V4.16699C13.9585 4.50866 13.6752 4.79199 13.3335 4.79199Z"
                    fill="#292D32" />
                <path
                    d="M7.08333 12.0829C6.975 12.0829 6.86667 12.058 6.76667 12.0163C6.65833 11.9746 6.575 11.9163 6.49167 11.8413C6.34167 11.6829 6.25 11.4746 6.25 11.2496C6.25 11.1413 6.275 11.0329 6.31667 10.9329C6.35833 10.8329 6.41667 10.7413 6.49167 10.6579C6.575 10.5829 6.65833 10.5246 6.76667 10.4829C7.06667 10.3579 7.44167 10.4246 7.675 10.6579C7.825 10.8163 7.91667 11.0329 7.91667 11.2496C7.91667 11.2996 7.90833 11.358 7.9 11.4163C7.89167 11.4663 7.875 11.5163 7.85 11.5663C7.83333 11.6163 7.80833 11.6663 7.775 11.7163C7.75 11.7579 7.70833 11.7996 7.675 11.8413C7.51667 11.9913 7.3 12.0829 7.08333 12.0829Z"
                    fill="#292D32" />
                <path
                    d="M9.99984 12.0837C9.8915 12.0837 9.78317 12.0587 9.68317 12.0171C9.57484 11.9754 9.4915 11.917 9.40817 11.842C9.25817 11.6837 9.1665 11.4754 9.1665 11.2504C9.1665 11.142 9.19151 11.0337 9.23317 10.9337C9.27484 10.8337 9.33317 10.7421 9.40817 10.6587C9.4915 10.5837 9.57484 10.5254 9.68317 10.4837C9.98317 10.3504 10.3582 10.4254 10.5915 10.6587C10.7415 10.8171 10.8332 11.0337 10.8332 11.2504C10.8332 11.3004 10.8248 11.3587 10.8165 11.4171C10.8082 11.4671 10.7915 11.5171 10.7665 11.5671C10.7498 11.6171 10.7248 11.667 10.6915 11.717C10.6665 11.7587 10.6248 11.8004 10.5915 11.842C10.4332 11.992 10.2165 12.0837 9.99984 12.0837Z"
                    fill="#292D32" />
                <path
                    d="M12.9168 12.0837C12.8085 12.0837 12.7002 12.0587 12.6002 12.0171C12.4918 11.9754 12.4085 11.917 12.3252 11.842C12.2918 11.8004 12.2585 11.7587 12.2252 11.717C12.1918 11.667 12.1668 11.6171 12.1502 11.5671C12.1252 11.5171 12.1085 11.4671 12.1002 11.4171C12.0918 11.3587 12.0835 11.3004 12.0835 11.2504C12.0835 11.0337 12.1752 10.8171 12.3252 10.6587C12.4085 10.5837 12.4918 10.5254 12.6002 10.4837C12.9085 10.3504 13.2752 10.4254 13.5085 10.6587C13.6585 10.8171 13.7502 11.0337 13.7502 11.2504C13.7502 11.3004 13.7418 11.3587 13.7335 11.4171C13.7252 11.4671 13.7085 11.5171 13.6835 11.5671C13.6668 11.6171 13.6418 11.667 13.6085 11.717C13.5835 11.7587 13.5418 11.8004 13.5085 11.842C13.3502 11.992 13.1335 12.0837 12.9168 12.0837Z"
                    fill="#292D32" />
                <path
                    d="M7.08333 15C6.975 15 6.86667 14.975 6.76667 14.9333C6.66667 14.8917 6.575 14.8333 6.49167 14.7583C6.34167 14.6 6.25 14.3833 6.25 14.1667C6.25 14.0583 6.275 13.95 6.31667 13.85C6.35833 13.7417 6.41667 13.65 6.49167 13.575C6.8 13.2667 7.36667 13.2667 7.675 13.575C7.825 13.7333 7.91667 13.95 7.91667 14.1667C7.91667 14.3833 7.825 14.6 7.675 14.7583C7.51667 14.9083 7.3 15 7.08333 15Z"
                    fill="#292D32" />
                <path
                    d="M9.99984 15C9.78317 15 9.5665 14.9083 9.40817 14.7583C9.25817 14.6 9.1665 14.3833 9.1665 14.1667C9.1665 14.0583 9.19151 13.95 9.23317 13.85C9.27484 13.7417 9.33317 13.65 9.40817 13.575C9.7165 13.2667 10.2832 13.2667 10.5915 13.575C10.6665 13.65 10.7248 13.7417 10.7665 13.85C10.8082 13.95 10.8332 14.0583 10.8332 14.1667C10.8332 14.3833 10.7415 14.6 10.5915 14.7583C10.4332 14.9083 10.2165 15 9.99984 15Z"
                    fill="#292D32" />
                <path
                    d="M12.9168 15.0004C12.7002 15.0004 12.4835 14.9087 12.3252 14.7587C12.2502 14.6837 12.1918 14.5921 12.1502 14.4838C12.1085 14.3838 12.0835 14.2754 12.0835 14.1671C12.0835 14.0587 12.1085 13.9504 12.1502 13.8504C12.1918 13.7421 12.2502 13.6504 12.3252 13.5754C12.5168 13.3838 12.8085 13.2921 13.0752 13.3504C13.1335 13.3587 13.1835 13.3754 13.2335 13.4004C13.2835 13.4171 13.3335 13.4421 13.3835 13.4754C13.4252 13.5004 13.4668 13.5421 13.5085 13.5754C13.6585 13.7338 13.7502 13.9504 13.7502 14.1671C13.7502 14.3837 13.6585 14.6004 13.5085 14.7587C13.3502 14.9087 13.1335 15.0004 12.9168 15.0004Z"
                    fill="#292D32" />
                <path
                    d="M17.0832 8.2002H2.9165C2.57484 8.2002 2.2915 7.91686 2.2915 7.5752C2.2915 7.23353 2.57484 6.9502 2.9165 6.9502H17.0832C17.4248 6.9502 17.7082 7.23353 17.7082 7.5752C17.7082 7.91686 17.4248 8.2002 17.0832 8.2002Z"
                    fill="#292D32" />
                <path
                    d="M13.3333 18.9587H6.66667C3.625 18.9587 1.875 17.2087 1.875 14.167V7.08366C1.875 4.04199 3.625 2.29199 6.66667 2.29199H13.3333C16.375 2.29199 18.125 4.04199 18.125 7.08366V14.167C18.125 17.2087 16.375 18.9587 13.3333 18.9587ZM6.66667 3.54199C4.28333 3.54199 3.125 4.70033 3.125 7.08366V14.167C3.125 16.5503 4.28333 17.7087 6.66667 17.7087H13.3333C15.7167 17.7087 16.875 16.5503 16.875 14.167V7.08366C16.875 4.70033 15.7167 3.54199 13.3333 3.54199H6.66667Z"
                    fill="#292D32" />
            </svg>
            <input type="text"
            placeholder="Select Date"
            class="form-control"
            bsDaterangepicker
            [maxDate]="today"
            formControlName="datepicker"
            (keydown)="$event.preventDefault();"
            [bsConfig]="{ keepDatesOutOfRules: true, adaptivePosition: true, rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }" />
          </div>
        <div class="fillterGroupBy fillterFeilds">
            <label for="">Group By</label>
            <div class="groupByArrow">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.735 7.08301C5.95473 7.07673 6.15562 6.99512 6.31885 6.81934L11.0901 1.93513C11.2282 1.79701 11.3035 1.62123 11.3035 1.41406C11.3035 0.999721 10.977 0.666992 10.5627 0.666992C10.3618 0.666992 10.1672 0.748605 10.0228 0.892997L5.74128 5.29381L1.4472 0.892997C1.3028 0.754883 1.11447 0.666992 0.907296 0.666992C0.492955 0.666992 0.166504 0.999721 0.166504 1.41406C0.166504 1.62123 0.241839 1.79701 0.379953 1.93513L5.15744 6.81934C5.32694 6.99512 5.51528 7.08301 5.735 7.08301Z" fill="#414E62"/>
                </svg>
            </div>
            <select name="" id=""  formControlName="groupBy">
                <option *ngFor="let filter of groupByFilter" [value]="filter">{{ filter | titlecase }}</option>
            </select>
        </div>
        <button (click)="applyFilter($event)">Apply Filter</button>
        <p *ngIf="showDayWiseErrorMsg" #showDayWiseError class="textErrorMesg">You have selected a date range of more than 7 days. When grouping by day, we limit the range to 7 days. You can either shorten the date range or switch to week or month view for a broader overview.</p>
        <p *ngIf="showNintiyDayErrorMsg" #showNintiyDayErrorMsg class="textErrorMesg nintiyDayErrorMsg">Limit exceeded! Please choose a date range that is 92 days or less.</p>
    </div>
    <div class="ad_FillterSection ad_wordCountSection">
        <ul>
            <li>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.66113 15.3337V8.66699C4.66113 8.1417 4.7646 7.62156 4.96561 7.13626C5.16663 6.65096 5.46127 6.21 5.83271 5.83857C6.20414 5.46713 6.6451 5.17249 7.1304 4.97147C7.6157 4.77045 8.13585 4.66699 8.66113 4.66699C9.18642 4.66699 9.70656 4.77045 10.1919 4.97147C10.6772 5.17249 11.1181 5.46713 11.4896 5.83857C11.861 6.21 12.1556 6.65096 12.3567 7.13626C12.5577 7.62156 12.6611 8.1417 12.6611 8.66699V15.3337M4.66113 11.3337H12.6611M25.9945 7.33366V15.3337M4.66113 20.667H20.6611M4.66113 26.0003H12.6611M17.9945 26.0003L20.6611 28.667L27.3278 22.0003M17.9945 11.3337C17.9945 12.3945 18.4159 13.4119 19.166 14.1621C19.9162 14.9122 20.9336 15.3337 21.9945 15.3337C23.0553 15.3337 24.0727 14.9122 24.8229 14.1621C25.573 13.4119 25.9945 12.3945 25.9945 11.3337C25.9945 10.2728 25.573 9.25538 24.8229 8.50523C24.0727 7.75509 23.0553 7.33366 21.9945 7.33366C20.9336 7.33366 19.9162 7.75509 19.166 8.50523C18.4159 9.25538 17.9945 10.2728 17.9945 11.3337Z"
                        stroke="#7A28A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <strong *ngIf="dashboardSummaryloader" class="shimmer-animation"></strong>
                <span  class="WordsCheckedText" *ngIf="!dashboardSummaryloader">{{dashboardSummaryData?.grammar?.word_count | number}}</span>
                <p>Words Checked for Grammar</p>
                <!-- <div class="analyticsTooltip">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div> -->
            </li>
            <li>
                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M28.3333 7H19C18.4533 7 18 6.54667 18 6C18 5.45333 18.4533 5 19 5H28.3333C28.88 5 29.3333 5.45333 29.3333 6C29.3333 6.54667 28.88 7 28.3333 7Z"
                        fill="#16A34A" />
                    <path
                        d="M28.3333 13.667H19C18.4533 13.667 18 13.2137 18 12.667C18 12.1203 18.4533 11.667 19 11.667H28.3333C28.88 11.667 29.3333 12.1203 29.3333 12.667C29.3333 13.2137 28.88 13.667 28.3333 13.667Z"
                        fill="#16A34A" />
                    <path
                        d="M28.3335 20.333H4.3335C3.78683 20.333 3.3335 19.8797 3.3335 19.333C3.3335 18.7863 3.78683 18.333 4.3335 18.333H28.3335C28.8802 18.333 29.3335 18.7863 29.3335 19.333C29.3335 19.8797 28.8802 20.333 28.3335 20.333Z"
                        fill="#16A34A" />
                    <path
                        d="M28.3335 27H4.3335C3.78683 27 3.3335 26.5467 3.3335 26C3.3335 25.4533 3.78683 25 4.3335 25H28.3335C28.8802 25 29.3335 25.4533 29.3335 26C29.3335 26.5467 28.8802 27 28.3335 27Z"
                        fill="#16A34A" />
                    <path
                        d="M10.8933 14.333H7.10667C5.05333 14.333 4 13.293 4 11.2263V7.43967C4 5.38634 5.04 4.33301 7.10667 4.33301H10.9067C12.96 4.33301 14.0133 5.37301 14.0133 7.43967V11.2397C14 13.293 12.96 14.333 10.8933 14.333ZM7.10667 6.33301C6.16 6.33301 6 6.49301 6 7.43967V11.2397C6 12.1863 6.16 12.3463 7.10667 12.3463H10.9067C11.8533 12.3463 12.0133 12.1863 12.0133 11.2397V7.43967C12.0133 6.49301 11.8533 6.33301 10.9067 6.33301H7.10667Z"
                        fill="#16A34A" />
                </svg>
                <strong *ngIf="dashboardSummaryloader" class="shimmer-animation"></strong>
                <span class="WordsParaphrasedText" *ngIf="!dashboardSummaryloader">{{dashboardSummaryData?.paraphrase?.word_count | number}}</span>
                <p > Words Paraphrased</p>
                <!-- <div class="analyticsTooltip">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div> -->
            </li>
            <li>
                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0801 19.5999L15.0801 21.5999L20.4134 16.2666" stroke="#FBBF24" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M13.9997 8.00033H19.333C21.9997 8.00033 21.9997 6.66699 21.9997 5.33366C21.9997 2.66699 20.6663 2.66699 19.333 2.66699H13.9997C12.6663 2.66699 11.333 2.66699 11.333 5.33366C11.333 8.00033 12.6663 8.00033 13.9997 8.00033Z"
                        stroke="#FBBF24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M21.9998 5.35938C26.4398 5.59938 28.6665 7.23937 28.6665 13.3327V21.3327C28.6665 26.666 27.3332 29.3327 20.6665 29.3327H12.6665C5.99984 29.3327 4.6665 26.666 4.6665 21.3327V13.3327C4.6665 7.25271 6.89317 5.59938 11.3332 5.35938"
                        stroke="#FBBF24" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <strong *ngIf="dashboardSummaryloader" class="shimmer-animation"></strong>
                <span class="WordsProofreadText" *ngIf="!dashboardSummaryloader">{{dashboardSummaryData?.AFE?.word_count | number}}</span>
                <p> Words Proofread</p>
                <!-- <div class="analyticsTooltip">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div> -->
            </li>
            <li>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22.6665 17.8663V21.8663C22.6665 27.1997 20.5332 29.333 15.1998 29.333H10.1332C4.79984 29.333 2.6665 27.1997 2.6665 21.8663V16.7997C2.6665 11.4663 4.79984 9.33301 10.1332 9.33301H14.1332"
                        stroke="#9333EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M22.6666 17.8663H18.4C15.2 17.8663 14.1333 16.7997 14.1333 13.5997V9.33301L22.6666 17.8663Z"
                        stroke="#9333EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.4668 2.66699H20.8001" stroke="#9333EA" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M9.3335 6.66699C9.3335 4.45366 11.1202 2.66699 13.3335 2.66699H16.8268" stroke="#9333EA"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M29.3331 10.667V18.9203C29.3331 20.987 27.6531 22.667 25.5864 22.667" stroke="#9333EA"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M29.3335 10.667H25.3335C22.3335 10.667 21.3335 9.66699 21.3335 6.66699V2.66699L29.3335 10.667Z"
                        stroke="#9333EA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <strong *ngIf="dashboardSummaryloader" class="shimmer-animation"></strong>
                <span  class="ReportsGeneratedText" *ngIf="!dashboardSummaryloader">{{dashboardSummaryData?.report_generated?.report_count | number}}</span>
                <p>Reports Generated</p>
                <!-- <div class="analyticsTooltip">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div> -->
            </li>
        </ul>
    </div>
    <div class="ad_FillterSection MenusTabSection">
        <ul role="tablist" class="nav nav-tabs">
            <li class="nav-item">
              <a href="#grammerTabs" role="tab" data-toggle="tab" class="nav-link active" (click)="fetchGrammerWordCountSummary()">Grammar</a>
            </li>
            <li class="nav-item">
              <a href="#ParaphraserTabs" role="tab" data-toggle="tab" class="nav-link" (click)="fetchParaphraseWordCountSummary()"> Paraphraser </a>
            </li>
            <li class="nav-item">
              <a href="#ProofreadFileTabs" role="tab" data-toggle="tab" class="nav-link" (click)="fetchProofreadWordCountSummary()">Proofread File</a>
            </li>
            <li class="nav-item">
                <a href="#ReportsTabs" role="tab" data-toggle="tab" class="nav-link" (click)="fetchGeneratedReportCountSummary()">Reports</a>
              </li>
        </ul>

        <div class="tab-content NoDataAvailable" style="display: none;">
            <img src="../../../../assets/images/NoDataAvailable.svg" alt="">
            <p>No data available to display please 
                use Paraphraser</p>
        </div>

        <div class="tab-content">
            <div class="tab-pane active" role="tabpanel" id="grammerTabs">
                <div class="row" *ngIf="grammarSummaryloader">
                    <div class="col-md-12">
                        <div class="commonLoaderSection shimmer-animation"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 80%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 60%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 50%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 40%;"></div>
                    </div>
                </div>
                <div class="row" *ngIf="!grammarSummaryloader">
                    <div class="col-md-12">
                        <div class="barChartSection" style="border-bottom: solid 0.5px #cccccc !important;">
                            <div class="">
                                <fusioncharts width="100%" height="350" type="Column2d"  placeholder=" "
                                [dataSource]="grammerDataSource"  dataFormat="json" dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- <div class="circleChartLoader">
                            <p class="shimmer-animation"></p>
                            <p class="shimmer-animation"></p>
                            <p class="shimmer-animation"></p>
                        </div> -->
                        <div class="circleChartSection">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="doughnut2d" [dataSource]="grammerUsageAppsData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="circleChartSection" style="border: none;">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="pie2d" [dataSource]="grammerLanguageWiseData"  dataFormat="json"
                                 dataEmptyMessage="No data to display"  baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" role="tabpanel" id="ParaphraserTabs">
                <div class="row" *ngIf="paraphraseSummaryloader">
                    <div class="col-md-12">
                        <div class="commonLoaderSection shimmer-animation"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 80%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 60%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 50%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 40%;"></div>
                    </div>
                </div>
                <div class="row" *ngIf="!paraphraseSummaryloader">
                    <div class="col-md-12">
                        <div class="barChartSection">
                            <div class="">
                                <fusioncharts width="100%" height="350" type="Column2d"  placeholder=" " [dataSource]="paraphraseDataSource"
                                  dataFormat="json" dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="circleChartSection">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="doughnut2d" [dataSource]="paraphraseUsageAppsData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="circleChartSection" style="border: none;">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="pie2d" [dataSource]="paraphraseLanguageWiseData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="tab-pane" role="tabpanel" id="ProofreadFileTabs">
                <div class="row" *ngIf="afeSummaryloader">
                    <div class="col-md-12">
                        <div class="commonLoaderSection shimmer-animation"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 80%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 60%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 50%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 40%;"></div>
                    </div>
                </div>
                <div class="row" *ngIf="!afeSummaryloader">
                    <div class="col-md-12">
                        <div class="barChartSection" style="border-bottom: solid 0.5px #cccccc !important;">
                            <div class="">
                                <fusioncharts width="100%" height="350" type="Column2d"  placeholder=" " [dataSource]="afeDataSource"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="circleChartSection">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="Column2d" [dataSource]="afeUsageAppsData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="circleChartSection" style="border: none;">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="pie2d" [dataSource]="afeLanguageWiseData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" role="tabpanel" id="ReportsTabs">
                <div class="row" *ngIf="reportSummaryloader">
                    <div class="col-md-12">
                        <div class="commonLoaderSection shimmer-animation"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 80%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 60%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 50%;"></div>
                        <div class="commonLoaderSection shimmer-animation" style="width: 40%;"></div>
                    </div>
                </div>
                <div class="row" *ngIf="!reportSummaryloader">
                    <div class="col-md-6">
                        <div class="barChartSection" style="border-right: solid 1px #9CA3AF; border-bottom: none;">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="Column2d"  placeholder=" " [dataSource]="journalFinderData"
                                  dataFormat="json" dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="barChartSection" style="border-right: none;">
                            <div class="">
                                <fusioncharts width="100%" height="400" type="Column2d" [dataSource]="citationCheckerData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="circleChartSection" style="padding-top:60px; border-top: solid 1px #9CA3AF;border-right: none;display: flex;align-items: center;justify-content: center;">
                            <div class="">
                                <fusioncharts width="500" height="400" type="pie2d" [dataSource]="plagCheckData"  dataFormat="json"
                                 dataEmptyMessage="No data to display" baseChartMessageFontSize=16px>
                              </fusioncharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>