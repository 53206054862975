<div class="container error_popup_section" fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap.xs="0" fxLayout="row">
  <div class="row">
    <div class="col-sm-12">
      <div class="popup_heading">
        <h2>Revision Summary</h2>
        <button class="cancelbuttons" mat-dialog-close>
          <img src="../../../../assets/images/closeIcons.png" alt="">
        </button>
        <p>A summary of revisions suggested by Trinka is presented below.</p>
      </div>
  
      <div class="newCategoryMappingSection" *ngIf="scoringVersion == 'v1'">
        <div class="totalErrorCount">
            <h3>Total Revisions: {{fileData.error_report.total_corrections+fileData.error_report.total_suggestions}}</h3>
        </div>
          <div class="summaryWrapperSection">
            <ul>
              <ng-container *ngFor="let obj of dataSource; let i = index;">
                <li  *ngIf="obj.isVisible">
                  <p>{{obj.key}}  {{obj.displayIndex}}</p>
                  <span>{{obj.value}}</span>
                </li>
              </ng-container>
            </ul>
          </div>
      </div>

      <div class="newCategoryMappingSection" *ngIf="scoringVersion == 'v2'">
        <div class="totalErrorCount">
          <h3>Total Revisions: {{scoring_report.correctness_count+scoring_report.formal_tone_count+scoring_report.conciseness_count+scoring_report.readbility_count+scoring_report.inclusive_count}}</h3>
        </div>

        <div class="summaryWrapperSection">
          <div class="LeftnewCategoryMapping">
            <ul>
              <li>
                <p><b>Correctness</b></p> 
                <span><b> {{scoring_report.correctness_count}}</b></span>
              </li>
              <li class="listItemIndent" *ngFor="let obj of categoryMappingListOfArray['Correctness']; let i = index;">
                  <p>{{obj.lang_category}}</p> 
                  <span>{{obj.count}}</span>
              </li>
            </ul>
          </div>
          <div class="RightnewCategoryMapping">
            <ul>
              <li style="padding-left: 0;">
                <p><b>Readability</b></p>
                <span><b> {{scoring_report.readbility_count}}</b></span>
              </li>
              <li class="listItemIndent" *ngFor="let obj of categoryMappingListOfArray['Readability']; let i = index;">
                <p>{{obj.lang_category}}</p> 
                <span>{{obj.count}}</span>
              </li>

              <li>
                <p><b>Conciseness</b></p>
                <span><b> {{scoring_report.conciseness_count}}</b></span>
              </li>
              <li class="listItemIndent" *ngFor="let obj of categoryMappingListOfArray['Conciseness']; let i = index;">
                <p>{{obj.lang_category}}</p> 
                <span>{{obj.count}}</span>
              </li>

              <li>
                <p><b>Formal Tone</b></p>
                <span><b> {{scoring_report.formal_tone_count}}</b></span>
              </li>
              <li class="listItemIndent" *ngFor="let obj of categoryMappingListOfArray['Formal Tone']; let i = index;">
                <p>{{obj.lang_category}}</p> 
                  <span>{{obj.count}}</span>
              </li>
                
          </ul>
          <ul *ngIf="inclusiveLang">
            <li>
              <p><b>Inclusive Language</b></p> 
              <span><b> {{scoring_report.inclusive_count}}</b></span>
            </li>
            <li class="listItemIndent" *ngFor="let obj of categoryMappingListOfArray['Inclusive Language']; let i = index;">
              <p>{{obj.lang_category}}</p> 
                  <span>{{obj.count}}</span>
          </li>
          </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>