<div class="plag_check_onboading_popup" id="onboard_plag">
    <div class="sliderPageOverlay">
        <svg width="180" height="557" viewBox="0 0 180 557" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" filter="url(#filter0_f_578_32172)">
            <circle cx="362" cy="195" r="162" fill="#CF7CE2"/>
            </g>
            <defs>
            <filter id="filter0_f_578_32172" x="0" y="-167" width="724" height="724" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_578_32172"/>
            </filter>
            </defs>
        </svg>
    </div>

    <div class="slides_body">
        <div class="slide" *ngIf="step==1">
            <div class="slide1_img">
                <img src="../../../../../assets/images/plagCheckSliderOneImg.png" alt="">
            </div>
            <div class="slide1_body">
                <img src="../../../../../assets/images/trinka-logo.png">
                <p class="slide1_tag"> Plagiarism Checker</p>
                <p class="slide1_title">Check for plagiarism with highest precision and accuracy</p>
                <ul>
                    <li>Search the largest database, updated daily</li>
                    <li>Choose between Internet only or all paid publication sources</li>
                    <li>Superior technology trusted by academic publishers</li>
                    <li>Get free plagiarism scores and source overviews every month</li>
                </ul>
            </div>

        </div>

        <div class="slide" *ngIf="step==2">
            <div class="slide2_img">
                <ul>
                    <li>
                        <span>1</span>
                        Upload a doc/docx/pdf
                    </li>
                    <li>
                        <span>2</span>
                        Select plagiarism type
                    </li>
                    <li>
                        <span>3</span>
                        Generate report 
                    </li>
                </ul>
            </div>
            <div class="slide1_body">
                <img src="../../../../../assets/images/trinka-logo.png">
                <p class="slide1_tag"> Plagiarism Checker</p>
                <p class="slide1_title">Check for plagiarism in<br>three simple steps by using our plagiarism checker</p>
                <p class="silde2_content">To check for plagiarism, submit a doc/docx/pdf file, choose the type of check—advanced or standard plagiarism check, and then click on generate the report. It may take up to 5 minutes for the report to be ready.</p>
            </div>

        </div>

        <div class="slide" *ngIf="step==3">
            <div class="slide1_img sliderStepThree">
                <ul style="visibility: hidden;">
                    <li>
                        <img src="../../../../../assets/images/ithentication_img.svg" alt="">
                    </li>
                    <li>
                        <img src="../../../../../assets/images/turnitinImg.png" alt="">
                    </li>
                </ul>
            </div>
            <div class="slide1_body bodyWithThreeSlides">
                <img src="../../../../../assets/images/trinka-logo.png">
                <p class="slide1_tag"> Plagiarism Checker</p>
                <p class="slide1_title">Reliable checks powered by iThenticate</p>
                <ul>
                    <li>Standard check - for plagiarism by comparing your content with sources available on internet. This report is useful for any non-academic use case.</li>
                    <li>Advanced check—powered by iThenticate—is the most comprehensive plagiarism report trusted by academic publishers. In this check, your document is compared with paid publications, in addition to internet sources.</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="popup_footer">

        <div class="skip">
            <button class="skip_btn" (click)="step>1 ? previous() :close()">
                <span *ngIf="step==1">Skip</span>
                <span *ngIf="step!=1">Previous</span>
            </button>
        </div>

        <div class="step_dots">
            <div class="dot" [ngClass]="{'active' : step==1}"> </div>
            <div class="dot" [ngClass]="{'active' : step==2}"> </div>
            <div class="dot" [ngClass]="{'active' : step==3}"> </div>
        </div>

        <div class="next">
            <button class="next_btn" (click)="next()">
                <span *ngIf="step!=3">Next</span>
                <span *ngIf="step==3">Let’s get started !</span>
            </button>
        </div>

    </div>

</div>