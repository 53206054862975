export interface Profile {
    clientid: number
    email_id: string
    firstname: string
    iccode: string
    isMonthlyInvoiceClient: boolean
    is_otp_verification: boolean
    is_verified: boolean
    lastname: string
    membershipid: string
    orcid: string
    othersubjectarea: string
    powermode_available_time: number
    powermode_end_time: string
    powermode_start_time: string
    powermode_trail_status: boolean
    subdomainId: number
    trial_expire_on: number
    userid: string
    message: string
    status: boolean
    is_purge: boolean
    purge_start_time: number
    purge_expire_on: number
}
export let profile_object: Profile={
    firstname: "",
    lastname: "",
    iccode: "",
    clientid: 0,
    subdomainId: 0,
    membershipid: "",
    orcid: "",
    userid: "",
    isMonthlyInvoiceClient: false,
    othersubjectarea: "",
    email_id: "",
    is_verified: false,
    trial_expire_on: 0,
    is_otp_verification: false,
    powermode_trail_status: false,
    powermode_start_time: null,
    powermode_end_time: null,
    powermode_available_time: 0,
    message: "",
    status: false,
    is_purge: false,
    purge_start_time: 0,
    purge_expire_on: 0
}