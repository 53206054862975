import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-analytics-dashboard-learn-more',
  templateUrl: './analytics-dashboard-learn-more.component.html',
  styleUrls: ['./analytics-dashboard-learn-more.component.scss']
})
export class AnalyticsDashboardLearnMoreComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AnalyticsDashboardLearnMoreComponent>) { }

  ngOnInit() {

  }

  close(){
    this.dialogRef.close();
  }

}
