<div class="trinkaUpdateModal">
    <!-- *ngIf="swtichToOldNewEditor" -->
    <div class="trinkaUpdateHeader">
        <h2>Important updates on the Trinka Cloud Editor</h2>
        <button style="display: none;" (click)="closeModal(true)">
            <img src="../../../../assets/images/close-black.svg" alt="">
        </button>
    </div>
    <div class="trinkaUpdateBodySection">
        <div class="svgRightSide">
            <svg fill="none" height="557" viewBox="0 0 180 557" width="180" xmlns="http://www.w3.org/2000/svg"
                data-gtm-vis-recent-on-screen13498092_497="7065" data-gtm-vis-first-on-screen13498092_497="7065"
                data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                <g filter="url(#filter0_f_578_32172)" opacity="0.5" data-gtm-vis-first-on-screen13498092_497="18009"
                    data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                    <circle cx="362" cy="195" fill="#CF7CE2" r="162" data-gtm-vis-first-on-screen13498092_497="18010"
                        data-gtm-vis-total-visible-time13498092_497="100" data-gtm-vis-has-fired13498092_497="1">
                    </circle>
                </g>
                <defs data-gtm-vis-polling-id13498092_497="3477">
                    <filter color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="724"
                        id="filter0_f_578_32172" width="724" x="0" y="-167" data-gtm-vis-polling-id13498092_497="3478">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"
                            data-gtm-vis-polling-id13498092_497="3479"></feFlood>
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape"
                            data-gtm-vis-polling-id13498092_497="3480"></feBlend>
                        <feGaussianBlur result="effect1_foregroundBlur_578_32172" stdDeviation="100"
                            data-gtm-vis-polling-id13498092_497="3481"></feGaussianBlur>
                    </filter>
                </defs>
            </svg>
        </div>
        <p><strong>We are excited to launch the latest and most advanced version of the Trinka Cloud Editor!</strong> With this new version, you can write, copy and paste text, accept or reject suggestions, and apply formatting with greater ease.</p>
        <h3 style="margin-top: 24px;">What's New and Improved?</h3>
        <ul>
            <li><span>Improved Document Formatting:</span> The new Cloud Editor offers more formatting options to achieve your desired presentation. Additionally, we have fixed inconsistent line spacing and text formatting issues, and we have made necessary improvements so that your document's formatting is preserved while uploading and downloading files.</li>
            <li><span>More Reliable Copy and Paste:</span> Responding to user feedback, we have further improved copy and paste functionality, making the process error-free while preserving almost the entire formatting.</li>
            <li><span>Better Math Equations Handling:</span> We have improved the display of mathematical equations for clearer, more accurate representation. This benefits technical and academic writing, ensuring effective equation handling.</li>
            <li><span>Improved Performance:</span> We have optimized the performance for handling large files, enabling you to work with documents up to 15,000 words smoothly. Please note that for files with a word count greater than 15,000 words, we encourage you to use the Proofread File feature.</li>
        </ul>
        <p style="margin-top: 24px;">We hope these updates enhance your writing experience. Keep sharing your feedback to help us serve you better!</p>
    </div>
    <div class="trinkaUpdateFooterSection">
        <button (click)="closeModal(true)">Okay</button>
        <!-- <a>Switch to the Old Editor</a> -->
    </div>
    <!-- <div class="trinkaUpdateFooterSection" *ngIf="!data.isNewEditor">
        <button (click)="closeModal(true)">Switch to the New Editor</button>
        <a>Continue to the Old Editor</a>
    </div> -->
</div>
<!-- <div class="trinkaUpdateModal switchEditorSection" *ngIf="!swtichToOldNewEditor">
    <div class="SVGwrapper">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    <p *ngIf="data.isNewEditor">You have switched back to the Old Editor</p>
    <p *ngIf="!data.isNewEditor">You have switched to the New Editor</p>
    <button>Close</button>
</div> -->