import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'resetFilter',
    pure: false
  })
  export class ResetFilterPipe implements PipeTransform {
    transform(items: any, filter: any): any {
      
      if (!items) return [];
      
      // if (currentfilter.length == 0) return this.resetArticles(items);

      items.filter(journal => {
        let singleGraph = {};
        let graphData = [];
        let articlecount = 0;
        journal.similar_article_data.filter(article => {
          
          article.to_show = true;
          
          if(!singleGraph.hasOwnProperty(article.year)){
            singleGraph[article.year] = 0;
          }
          singleGraph[article.year] =  singleGraph[article.year] + 1;
          articlecount = articlecount + 1;
        });
        Object.keys(singleGraph).forEach((key) => {
          let current = {'label': key, 'value': singleGraph[key]};
          graphData.push(current);
        });

        graphData.sort(function(a, b) {
          return a.label - b.label;
        });
        journal.similar_article_count = articlecount;
        journal.graphdata.data = graphData;
        // console.log("journal: ",journal);
      });

      return items;
    }
  }